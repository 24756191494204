import React, { useEffect, useState } from "react";
import { Card, CardHeader, Row, Col, Button, Table, CardFooter } from "reactstrap";
import { Pagination } from "../Pagination";

const tableData = [
	{
		id: 1,
		SurveyorName: "Dadan Khan",
		patientName: "Afzal Niaz",
		gender: "Male",
		age: "65",
		address: "11,Kaghan Road,Sector F-8/3,Near Faisal Avenue, Islamabad",
		severity: "Severe",
		class: "badge badge-danger"
	},
	{
		id: 2,
		SurveyorName: "Shahzaib Niazi",
		patientName: "Sohail Ahmed",
		gender: "Male",
		age: "42",
		address: "H.No.29,F-8/3,Kaghan Road, Islamabad",
		severity: "Low",
		class: "badge badge-success"
	},
	{
		id: 3,
		SurveyorName: "Shahzaib Niazi",
		patientName: "Syed Mustafa",
		gender: "Male",
		age: "22",
		address: "House #15,Street-30,F-6/1, Islamabad",
		severity: "Severe",
		class: "badge badge-danger"
	},
	{
		id: 4,
		SurveyorName: "Musa Ahmed",
		patientName: "Ahmed Ali",
		gender: "Male",
		age: "38",
		address: "H-13,Street No.28,Sector F-6/1, Islamabad",
		severity: "Mild",
		class: "badge badge-warning"
	},
	{
		id: 5,
		SurveyorName: "Faisal Ahmed",
		patientName: "Mir Adbullah",
		gender: "Male",
		age: "25",
		address: "H.No.10,Street-30,F-7/1, Islamabad",
		severity: "Severe",
		class: "badge badge-danger"
	},
	{
		id: 6,
		SurveyorName: "Kumail Raza",
		patientName: "Sain Niamat",
		gender: "Male",
		age: "43",
		address: "H.No.10,Street-30,F-7/1, Islamabad",
		severity: "Severe",
		class: "badge badge-danger"
	}
];

export const AnalysisTable = ({ data, tableColumns, title }) => {
	const [update, setupdate] = useState("");
	useEffect(() => {}, [update]);
	const appendAddress = () => {
		if (dataList.length > 0) {
			for (let i = 0; i < tableData.length; i++) {
				let index = Math.floor(Math.random() * dataList.length);
				tableData[i].address = dataList[index].address;
				// setupdate(update);
			}
		}
	};
	let dataList = [];
	if (data) {
		let keys = Object.keys(data);
		dataList = keys.map((key) => {
			return data[key];
		});
		appendAddress();
	} else dataList = tableData;

	return (
		<Row className="mt-1">
			<Col md="12">
				<Card className="main-card mb-1">
					<CardHeader>{title}</CardHeader>
					<Table responsive hover striped borderless className="align-middle mb-0">
						<thead>
							<tr>
								{tableColumns.map((item, ind) => (
									<th key={`an_table_th_${ind}`} className="text-center">
										{item.name}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{tableData ? (
								tableData.map((item, index) => (
									<tr key={`an_table_tr_${index}`}>
										<td className="text-center ">{item.id}</td>
										<td className="text-center">{item.SurveyorName}</td>
										<td className="text-center">{item.patientName}</td>
										<td className="text-center">{item.gender}</td>
										<td className="text-center">{item.age}</td>
										<td className="text-center">{item.address}</td>
										<td className="text-center">
											<div className={item.class}>{item.severity}</div>
										</td>
									</tr>
								))
							) : (
								<div className="text-center col-12 align-content-center align-items-center">
									<h4 className="align-self-center">No Submissions</h4>
								</div>
							)}
						</tbody>
					</Table>
					<CardFooter className="d-block text-center">
						{/* <Button className="btn-wide" color="success">
							Load More
						</Button> */}
						<Row className="justify-content-center mb-n3">
							<Pagination pageCount={10} handlePageClick={() => appendAddress} />
						</Row>
					</CardFooter>
				</Card>
			</Col>
		</Row>
	);
};
