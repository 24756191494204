import React from 'react';
import { Row } from 'reactstrap';
import LoaderSVG from '../../../assets/images/loader.svg';

const ScreenLoader = props => {
    const { loading } = props;
    if (loading) {
        return (
    
            <Row style={{ alignSelf: "center", justifyContent: "center" }}>
                <img src={LoaderSVG} className="loadersvg" img="source" alt="loader" />
            </Row>
        );
    }
    else return "";
};

export default ScreenLoader;