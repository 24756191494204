import React, { Component } from 'react';
import { Circle } from 'google-maps-react';
import { Col } from 'reactstrap';
import { GoogleMap } from '../../common';
import { BasicSlider } from '../../../../components/Sliders/BasicSlider';

class Map extends Component {

    createCircles = clusters => {
        const circles = [];
        Object.keys(clusters).forEach(circleId => {
            const { lat, lng, radius, date } = clusters[circleId];
            const circle = (
                <Circle
                    key={`circle_${circleId}`}
                    center={{
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }}
                    radius={radius}
                    strokeColor="black"
                    strokeOpacity={1}
                    strokeWeight={3}
                    fillColor="#D92550"
                    fillOpacity={0.7}
                    onClick={_ => this.props.onClickCircle(date, circleId)}
                />
            );
            circles.push(circle);
        });
        return circles;
    }

    handleOnSlide = value => this.props.onSelectWeek(value);

    getSlider = _ => {
        const { weekDates, selectedWeek } = this.props;
        const dates = weekDates[selectedWeek];
        const firstDate = dates[0];
        const lastDate = dates[dates.length - 1];
        return (
            <Col>
                <h6 className="sliderTitle">{`Date: ${firstDate} - ${lastDate}`}</h6>
                <BasicSlider
                    min={0}
                    max={weekDates.length - 1}
                    defaultValue={selectedWeek}
                    onSlide={this.handleOnSlide}
                />
            </Col>
        );
    };

    render() {
        const { clusters, getMapRef } = this.props;
        const slider = this.getSlider();
        const circles = this.createCircles(clusters)
        return (
            <GoogleMap
                circles={circles}
                rangeSlider={slider}
                getRef={getMapRef}
            />
        );
    }
}

export default Map;