import React, { Fragment, Component } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input, Toast, ToastBody, ToastHeader, Spinner, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { Auth } from "../../services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = { email: "", password: "", loading: false, show: false, errorShown: false };
	}

	loginUser = async () => {
		this.setState({ loading: true });
		try {
			const { email, password } = this.state;
			const response = await Auth.login(email, password);
			const {
				data: {
					data: { token }
				}
			} = response;
			Auth.setAuthorizationToken(token);
			Auth.setPortalUsers(response.data.data.portalUsers);
			this.setState({ loading: false });
			this.props.history.push("/");
		} catch (error) {
			this.setState({ loading: false });
			if (error.response && error.response.status && error.response.status === 401) {
				this.toastError(error.response.data.data.message);
			} else {
				this.toastError (error.message);
			}
		}
	};

	toastError = (message) => {
		if (!this.state.errorShown) {
			this.setState ({errorShown: true}, () => {
				toast.error(message, {onClose: () => this.setState({errorShown: false})});
			});
		}
	}
	render() {
		if (Auth.getAuthorizationToken()) this.props.history.push("/");
		return (
			<Fragment>
				<div className="h-100 bg-strong-bliss bg-animation">
					<div className="d-flex h-100 justify-content-center align-items-center">
						<Col md="8" className="mx-auto app-login-box">
							<div className="modal-dialog w-100 mx-auto">
								<div className="modal-content">
									<div className="modal-body">
										<div className="h5 modal-title text-center">
											<h4 className="mt-2">
												<div>Welcome back,</div>
												<span>Please sign in to your account below.</span>
											</h4>
										</div>
										<Form>
											<Row form>
												<Col md={12}>
													<FormGroup>
														<Input
															type="email"
															name="email"
															id="exampleEmail"
															placeholder="Email here..."
															onChange={(email) => this.setState({ email: email.target.value })}
															//   value={this.state.email}
														/>
													</FormGroup>
												</Col>
												<Col md={12}>
													<FormGroup>
														<InputGroup>
															<Input
																type={this.state.show ? "email" : "password"}
																name="password"
																id="examplePassword"
																placeholder="Password here..."
																onChange={(password) => this.setState({ password: password.target.value })}
																//   value={this.state.password}
															/>
															<InputGroupAddon addonType="append">
																<InputGroupText onClick={() => this.setState({ show: !this.state.show })}>
																	<FontAwesomeIcon icon={this.state.show ? faEyeSlash : faEye} />
																</InputGroupText>
															</InputGroupAddon>
														</InputGroup>
													</FormGroup>
												</Col>
											</Row>
											<FormGroup check>
												<Input type="checkbox" name="check" id="exampleCheck" />
												<Label for="exampleCheck" check>
													Keep me logged in
												</Label>
											</FormGroup>
										</Form>
										{/* <div className="divider" /> */}
									</div>
									<div className="modal-footer clearfix">
										<div className="float-right">
											<Button disabled={this.state.loading} color="primary" size="lg" onClick={() => this.loginUser()}>
												{this.state.loading ? <Spinner size="sm" /> : "Login to Dashboard"}
											</Button>
										</div>
									</div>
								</div>
							</div>
							<div className="text-center text-white opacity-8 mt-3">Copyright &copy; SurveyAuto 2020</div>
						</Col>
					</div>
				</div>
			</Fragment>
		);
	}
}
