import React, { Fragment } from "react";
import ReactSelect from "../../../components/Dropdowns/ReactSelect";
const products = [
	{ value: "Sayana", label: "Sayana Press" },
	{ value: "A", label: "Other Product" },
	{ value: "B", label: "Other Product" }
];

class SearchBox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			product: false
		};
	}

	render() {
		return (
			<Fragment>
				<ReactSelect
					options={products}
					placeholder={"Search Products"}
					onChange={this.props.onProductChange}
					value={this.props.top.selectedProduct}
				/>
			</Fragment>
		);
	}
}

export default SearchBox;
