const nextPageDelay = 2000;
const searchNearby = (element, position, type, radius) => {
	try {
		// Initialize Google Autocomplete
		/*global google*/ // To disable any eslint 'google not defined' errors
		return new Promise((resolve, reject) => {
			const location = new google.maps.LatLng(position.lat, position.lng);
			let map = new google.maps.Map(element, { center: location, zoom: 15 });
			const service = new google.maps.places.PlacesService(map);
			const request = {
				location: location,
				radius: radius,
				type: [type]
			};
			let result = [];
			service.nearbySearch(request, (response, status, page) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					response.forEach((data) => {
						result.push({
							distance: calculateDistance(position, { lat: data.geometry.location.lat(), lng: data.geometry.location.lng() }),
							lat: data.geometry.location.lat(),
							lng: data.geometry.location.lng(),
							name: data.name,
							id: data.id,
							placeId: data.place_id,
							type: data.types.join(", ")
						});
					});
					if (page.hasNextPage) {
						setTimeout(() => {
							page.nextPage();
						}, nextPageDelay);
					} else {
						resolve(result);
					}
				} else {
					reject(status);
				}
			});
		});
	} catch (e) {
		throw e;
	}
};

const calculateDistance = (loc1, loc2) => {
	try {
		// const R = 6371e3; // metres
		const R = 6371; // kilometres
		const φ1 = (loc1.lat * Math.PI) / 180; // φ, λ in radians
		const φ2 = (loc2.lat * Math.PI) / 180;
		const Δφ = ((loc2.lat - loc1.lat) * Math.PI) / 180;
		const Δλ = ((loc2.lng - loc1.lng) * Math.PI) / 180;

		const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const d = R * c; // in kilometres
		return Math.round((d + Number.EPSILON) * 100) / 100;
	} catch (e) {
		throw e;
	}
};

const sortByDistance = (locationsWithDistance) => {
	try {
		return locationsWithDistance.sort((a, b) => (a.distance >= b.distance ? 1 : -1));
	} catch (e) {
		throw e;
	}
};

export default {
	searchNearby,
	calculateDistance,
	sortByDistance
};
