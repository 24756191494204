import React, { Component, Fragment } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { Polyline, Marker, InfoWindow } from "google-maps-react";
import GoogleMap from "../../common/google_map";
import Data from "../../data/tabular_data.json";
import Icon from "../../../../assets/images/location.png";
import { Link } from "react-router-dom";

class Map extends Component {
	state = { infoWindow: null, mapToggle: false, selectedUser: null };

	componentDidMount() {
		if (this.props.selectedUser) this.setState({ mapToggle: true }, () => this.setState({ mapToggle: false, selectedUser: this.props.selectedUser }));
	}

	componentDidUpdate(prevProps) {
		try {
			if (prevProps.selectedUser !== this.props.selectedUser) {
				this.setState({ mapToggle: true }, () => this.setState({ selectedUser: this.props.selectedUser, mapToggle: false }));
			}
		} catch (e) {}
	}

	drawMarkers = (_) => {
		const { selectedUser } = this.state;
		if (!selectedUser) return [];
		const { path } = Data.find((item) => item.id === selectedUser);
		return path.map((point, index) => {
			return <Marker key={`marker_${selectedUser}_${index}`} position={point} icon={Icon} onClick={() => this.handleMarkerClick(index)} />;
		});
	};

	drawPath = (_) => {
		const { selectedUser } = this.state;
		if (!selectedUser) return null;
		const { path } = Data.find((item) => item.id === selectedUser);
		return <Polyline path={path} strokeColor={"#b30000"} strokeOpacity={1.0} strokeWeight={5} />;
	};

	handleMarkerClick = (markerIndex) => {
		const { selectedUser } = this.state;
		const { path } = Data.find((item) => item.id === selectedUser);
		let { lat, lng } = path[markerIndex];
		const infoWindow = (
			<InfoWindow position={{ lat, lng }} visible={true}>
				<span className="badge badge-primary">{Math.ceil(Math.random() * 10)}</span>
			</InfoWindow>
		);
		this.setState({ mapToggle: true }, () => this.setState({ infoWindow, mapToggle: false }));
	};

	getDetailsCard = () => {
		const { selectedUser } = this.state;
		if (!selectedUser) return null;
		const { contact } = Data.find((item) => item.id === selectedUser);
		return (
			<div className="patientDetails m-2">
				<Card>
					<CardHeader className="text-center">Patient Details</CardHeader>
					<CardBody>
						{Object.keys(contact).map((key) => {
							return (
								<Fragment>
									{key === "Tested Positive" ? (
										<Link target="blank" to="/alert">
											<Row>
												<Col className="font-weight-bold">{key}</Col>
												<Col className="text-right font-weight-bold">{contact[key]}</Col>
											</Row>
										</Link>
									) : (
										<Row>
											<Col className="font-weight-bold">{key}</Col>
											<Col className="text-right font-weight-bold">{contact[key]}</Col>
										</Row>
									)}
								</Fragment>
							);
						})}
					</CardBody>
				</Card>
			</div>
		);
	};

	render() {
		const { infoWindow, mapToggle } = this.state;
		let polyline;
		if (!mapToggle) polyline = this.drawPath();
		return (
			<React.Fragment>
				{this.getDetailsCard()}
				<GoogleMap infoWindows={[infoWindow]} polylines={polyline ? [polyline] : null} markers={this.drawMarkers()} mapToggle={mapToggle} />
			</React.Fragment>
		);
	}
}

export default Map;
