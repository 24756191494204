const mapLegend = {
	population: [
		{ population: "15000 - 126000", color: "#f1c27d" },
		{ population: "126000 - 237000", color: "#b3cde0" },
		{ population: "237000 - 348000", color: "#83d0c9" },
		{ population: "348000 - 459000", color: "#e3c9c9" },
		{ population: "459000 - 570000", color: "#ff8b94" }
	],
	sec: [
		{ population: "SEC A", color: "#61E8DC" },
		{ population: "SEC B", color: "#F0B154" },
		{ population: "SEC C", color: "#F55864" }
	]
};

export default mapLegend;
