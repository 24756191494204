import React, { Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DropdownToggle, DropdownMenu, Nav, Button, NavItem, NavLink, UncontrolledButtonDropdown } from "reactstrap";
import { toast, Bounce } from "react-toastify";
import { faAngleDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import city3 from "../../../architectuiassets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../architectuiassets/utils/images/avatars/2.jpg";
import { Auth } from "../../../services";

class UserBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false
		};
	}

	notify2 = () =>
		(this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
			transition: Bounce,
			closeButton: true,
			autoClose: 5000,
			position: "bottom-center",
			type: "success"
		}));

	logout = () => {
		localStorage.clear();
		window.location = "/";
	};

	render() {
		let userData = Auth.getUserDetail();
		let user = "",
			company = "";
		if (userData) {
			user = userData.first_name + " " + userData.last_name;
			company = userData.company;
		} else {
			user = "Client";
			company = "Company";
		}
		return (
			<Fragment>
				<div className="header-btn-lg pr-0 pl-2 ml-2">
					<div className="widget-content p-0">
						<div className="widget-content-wrapper">
							<div className="widget-content-left">
								<UncontrolledButtonDropdown>
									<DropdownToggle color="link" className="p-0">
										{/* <img width={42} className="rounded-circle" src={avatar1} alt="" /> */}
										<FontAwesomeIcon icon={faUser} />
										<FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
									</DropdownToggle>
									<DropdownMenu right className="rm-pointers dropdown-menu-lg">
										<div className="dropdown-menu-header">
											<div className="dropdown-menu-header-inner bg-info">
												<div
													className="menu-header-image opacity-2"
													style={{
														backgroundImage: "url(" + city3 + ")"
													}}
												/>
												<div className="menu-header-content text-left">
													<div className="widget-content p-0">
														<div className="widget-content-wrapper">
															<div className="widget-content-left mr-3">
																{/* <img width={42} className="rounded-circle" src={avatar1} alt="" /> */}
																<FontAwesomeIcon icon={faUser} />
															</div>
															<div className="widget-content-left">
																<div className="widget-heading">{user}</div>
																<div className="widget-subheading opacity-8">{company}</div>
															</div>
															<div className="widget-content-right mr-2">
																<Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={() => this.logout()}>
																	Logout
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{/* <div
											className="scroll-area-xs"
											style={{
												height: "150px"
											}}
										>
											<PerfectScrollbar>
												<Nav vertical>
													<NavItem className="nav-item-header">Activity</NavItem>
													<NavItem>
														<NavLink href="#">Recover Password</NavLink>
													</NavItem>
													<NavItem className="nav-item-header">My Account</NavItem>
													<NavItem>
														<NavLink href="#">
															Settings
															<div className="ml-auto badge badge-success">New</div>
														</NavLink>
													</NavItem>
												</Nav>
											</PerfectScrollbar>
										</div> */}
									</DropdownMenu>
								</UncontrolledButtonDropdown>
							</div>
							<div className="widget-content-left  ml-3 header-user-info">
								<div className="widget-heading">{user}</div>
								<div className="widget-subheading">{company}</div>
							</div>

							{/* <div className="widget-content-right header-user-info ml-3">
                                <Button className="btn-shadow p-1" size="sm" onClick={this.notify2} color="info"
                                        id="Tooltip-1">
                                    <IoIosCalendar color="#ffffff" fontSize="20px" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={'Tooltip-1'}>
                                    Click for Toastify Notifications!
                                </UncontrolledTooltip>
                            </div> */}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default UserBox;
