import React, { Fragment, useState } from "react";
import { Nav, NavLink, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let tabIndex = 0;

const MegaMenu = ({ onChangeTopTabs, menuItems, top }) => {
	const tabIndexToggle = async (index) => {
		if (tabIndex === index) {
			tabIndex = null;
		} else {
			tabIndex = index;
		}
	};

	const handleClick = (e) => {
		let dropDowns = Array.from(document.querySelectorAll(".header-megamenu .nav-link"));
		e.preventDefault();
		dropDowns.forEach((node) => {
			node.classList.remove("activeLink");
		});
		if (tabIndex === null) {
			e.currentTarget.classList.remove("activeLink");
		} else {
			e.currentTarget.classList.add("activeLink");
		}
	};

	return (
		<Nav className="header-megamenu ml-2">
			{menuItems &&
				menuItems.length > 0 &&
				menuItems.map((item, i) => {
					return (
						<NavItem key={"nav-item" + i}>
							{/* <FontAwesomeIcon icon={item.icon} /> */}
							<NavLink
								href="#"
								onClick={(e) => {
									// tabIndexToggle(i);
									onChangeTopTabs(i);
									// handleClick(e);
								}}
								// selected={item.selected}
								className={i === top ? "activeLink" : ""}
								// className={item.selected ? "activeLink" : ""}
							>
								<i className={item.icon}> </i>
								<span className="ml-2">{item.label}</span>
							</NavLink>
						</NavItem>
					);
				})}
		</Nav>
	);
};

export default MegaMenu;
