import React from "react";
import Select from "react-select";

const ReactSelect = ({ value, onChange, options, placeholder }) => {
  return (
    <Select
      value={value}
      onChange={(value) => onChange(value)}
      options={options}
      placeholder={placeholder}
    />
  );
};

export default ReactSelect;
