import React from "react";
import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from "reactstrap";
import { Pagination } from "../Pagination";

const handleChangePage = () => {
  "";
};

export const NewsFeed = ({ data, tableColumns, title }) => {
  return (
    <div className="main-card newsFeed">
      <Card className="mt-3 h100">
        <CardHeader>{title}</CardHeader>
        <Table
          responsive
          hover
          striped
          borderless
          className="align-middle mb-0"
        >
          <thead>
            <tr>
              {tableColumns.map((item) => (
                <th className="text-center">{item.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((item, index) => (
                <tr>
                  <td className="text-center ">{item.name}</td>
                  <td className="text-center">{item.number}</td>
                  <td className="text-center">{item.cnic}</td>
                  <td className="text-center">{item.gender}</td>
                  <td className="text-center">{item.age}</td>
                  <td className="text-center">{item.result}</td>
                </tr>
              ))
            ) : (
              <div className="text-center col-12 align-content-center align-items-center">
                <h4 className="align-self-center">No Submissions</h4>
              </div>
            )}
          </tbody>
        </Table>
        <CardFooter className="d-block text-center mt-auto">
          {/* <Button className="btn-wide" color="success">
							Load More
						</Button> */}
          <Row className="justify-content-center mb-n3">
            <Pagination pageCount={3} handlePageClick={handleChangePage()} />
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};
