import React, { PureComponent } from "react";
import { Card, CardHeader, CardBody, CardFooter, Row } from "reactstrap";
import Table from "../../common/table";
import Data from "../../data/tabular_data.json";
import { sortTableData, paginate } from "../../utils/table_data";
import { Pagination } from "../../../../components/Pagination";

class PatientsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "srno",
      sortOrder: "asc",
      currentPage: 0,
    };
    const temp = Data.map((row) => ({
      ...row,
      id: parseInt(row.id, 10),
    }));
    this.tabularData = [];
    for (let i = 0; i < 6; i++) {
      this.tabularData = [...this.tabularData, ...temp];
    }
    this.tabularData = this.tabularData.map((item, index) => ({
      ...item,
      srno: index + 1,
    }));
    this.USERS_PER_PAGE = 100;
  }

  handleChangePage = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  handleSort = (sortBy, sortOrder) => {
    this.setState({ sortBy, sortOrder });
  };

  render() {
    const { sortBy, sortOrder, currentPage } = this.state;

    let tableData = [...this.tabularData];
    tableData = sortTableData(tableData, sortBy, sortOrder);
    const { data, totalPages } = paginate(
      tableData,
      currentPage,
      this.USERS_PER_PAGE
    );

    return (
      <Card className="main-card tracingList" style={{ maxHeight: "100%" }}>
        <CardHeader>Contacts</CardHeader>
        <CardBody className="position-relative tracingListTable">
          <Table
            data={data}
            headers={["#", "Patient Name", "Gender", "Age", "Severity"]}
            keys={["srno", "patient name", "gender", "age", "severity"]}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={this.handleSort}
            onRowClick={this.props.onSelectUser}
            rowClickKey="id"
          />
        </CardBody>
        <CardFooter>
          <Row className="justify-content-center w-100 mb-n3">
            <Pagination
              pageCount={totalPages}
              handlePageClick={this.handleChangePage}
              className="mb-0"
            />
          </Row>
        </CardFooter>
      </Card>
    );
  }
}

export default PatientsTable;
