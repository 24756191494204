import axios from "axios";
import Auth from "./auth";
const URL = process.env.REACT_APP_BACKEND_API_HOST + "/api/v1/alert";

class AlertService {
	static sendAlert(alert) {
		try {
			let mUrl = URL;
			let options = {
				headers: {
					Authorization: `${Auth.getAuthorizationToken()}`
				}
			};
			return axios
				.post(mUrl, alert, options)
				.then((response) => response)
				.catch((error) => Promise.reject(error));
		} catch (e) {
			throw e;
		}
	}
}

export default AlertService;
