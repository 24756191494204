import React, { Component } from "react";
import { Card, CardBody, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import GoogleMap from "./google_map";
import { Marker } from "google-maps-react";
import BlueIcon from "../../../assets/images/blue-dot-pastel-16.png";
import GreenIcon from "../../../assets/images/green-dot-pastel-16.png";
import RedIcon from "../../../assets/images/red-dot-pastel-16.png";
import YellowIcon from "../../../assets/images/yellow-dot-pastel-16.png";
// import TealIcon from "../../../assets/images/teal-dot-pastel-16.png";
// import PurpleIcon from "../../../assets/images/purple-dot-pastel-16.png";
import PinkIcon from "../../../assets/images/pink-dot-pastel-16.png";
import Loader from "react-loaders";

class MapModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSchools: true,
			showMarkets: true,
			showMosques: true,
			showStations: true,
			showHospitals: true,
			schoolsMarkers: [],
			marketsMarkers: [],
			mosquesMarkers: [],
			stationsMarkers: [],
			hospitalsMarkers: [],
			mapToggle: false
		};
		this.checkboxes = [
			{
				name: "showSchools",
				key: "schools",
				label: "Educational Institutes",
				color: "#D92550",
				className: "radioRed"
			},
			{
				name: "showMarkets",
				key: "markets",
				label: "Shopping Areas",
				color: "#F7B924",
				className: "radioYellow"
			},
			{
				name: "showMosques",
				key: "mosques",
				label: "Places of Worship",
				color: "#3AC47D",
				className: "radioGreen"
			},
			{
				name: "showStations",
				key: "stations",
				label: "Bus Stations",
				color: "#545CD8",
				className: "radioBlue"
			},
			// {
			// 	name: "showHospitals",
			// 	key: "hospitals",
			// 	label: "Hospitals",
			// 	color: "#16e0bd",
			// 	className: "radioTeal"
			// }
			{
				name: "showHospitals",
				key: "hospitals",
				label: "Hospitals",
				color: "#f65ca2",
				className: "radioTeal"
			}
		];
	}

	componentDidUpdate(prevProps) {
		try {
			let { schools, markets, mosques, stations, hospitals } = this.props;
			if (prevProps.schools !== schools || prevProps.markets !== markets || prevProps.mosques !== mosques || prevProps.stations !== stations || prevProps.hospitals !== hospitals) {
				this.buildMarkers();
			}
		} catch (e) {}
	}

	buildMarkers = () => {
		let { schools, markets, mosques, stations, hospitals } = this.props;
		// let { schoolsMarkers, marketsMarkers, mosquesMarkers, stationsMarkers } = this.state;
		let schoolsMarkers = [],
			marketsMarkers = [],
			mosquesMarkers = [],
			stationsMarkers = [],
			hospitalsMarkers = [];

		if (schools) {
			schools.forEach((school, ind) => {
				schoolsMarkers.push(
					<Marker
						key={`school_marker_${ind}`}
						position={{
							lat: parseFloat(school.lat),
							lng: parseFloat(school.lng)
						}}
						icon={RedIcon}
					/>
				);
			});
		}
		if (markets) {
			markets.forEach((market, ind) => {
				marketsMarkers.push(
					<Marker
						key={`market_marker_${ind}`}
						position={{
							lat: parseFloat(market.lat),
							lng: parseFloat(market.lng)
						}}
						icon={YellowIcon}
					/>
				);
			});
		}
		if (mosques) {
			mosques.forEach((mosque, ind) => {
				mosquesMarkers.push(
					<Marker
						key={`mosque_marker_${ind}`}
						position={{
							lat: parseFloat(mosque.lat),
							lng: parseFloat(mosque.lng)
						}}
						icon={GreenIcon}
					/>
				);
			});
		}
		if (stations) {
			stations.forEach((station, ind) => {
				stationsMarkers.push(
					<Marker
						key={`station_marker_${ind}`}
						position={{
							lat: parseFloat(station.lat),
							lng: parseFloat(station.lng)
						}}
						icon={BlueIcon}
					/>
				);
			});
		}
		if (hospitals) {
			hospitals.forEach((hospital, ind) => {
				hospitalsMarkers.push(
					<Marker
						key={`hospital_marker_${ind}`}
						position={{
							lat: parseFloat(hospital.lat),
							lng: parseFloat(hospital.lng)
						}}
						icon={PinkIcon}
					/>
				);
			});
		}
		this.setState({ mapToggle: true }, () => this.setState({ schoolsMarkers, marketsMarkers, mosquesMarkers, stationsMarkers, hospitalsMarkers, mapToggle: false }));
	};

	onCheckboxButtonClick = (value) => {
		let { showSchools, showMarkets, showMosques, showStations, showHospitals } = this.state;
		if (value === 0) showSchools = !showSchools;
		else if (value === 1) showMarkets = !showMarkets;
		else if (value === 2) showMosques = !showMosques;
		else if (value === 3) showStations = !showStations;
		else if (value === 4) showHospitals = !showHospitals;

		this.setState({ mapToggle: true }, () => this.setState({ showSchools, showMarkets, showMosques, showStations, showHospitals, mapToggle: false }));
	};

	render() {
		let { showMapModal, toggleMapModal, fetchingPlaces } = this.props;
		let { showSchools, showMarkets, showMosques, showStations, showHospitals, schoolsMarkers, marketsMarkers, mosquesMarkers, stationsMarkers, hospitalsMarkers, mapToggle } = this.state;
		let markers = [];
		if (showSchools) markers = [...markers, ...schoolsMarkers];
		if (showMarkets) markers = [...markers, ...marketsMarkers];
		if (showMosques) markers = [...markers, ...mosquesMarkers];
		if (showStations) markers = [...markers, ...stationsMarkers];
		// if (showStations) markers = [...markers, ...stationsMarkers];
		if (showHospitals) markers = [...markers, ...hospitalsMarkers];

		return (
			<Modal isOpen={showMapModal} onClose={toggleMapModal} showMask={false} size="lg" backdrop={true} keyboard={true}>
				<ModalHeader toggle={toggleMapModal}>
					<Row>
						{this.checkboxes.map((item, ind) => (
							<span key={`span_${item.key}`}>
								<input
									key={`checkbox_${item.key}`}
									type="checkbox"
									style={{ marginLeft: 20 }}
									defaultChecked={this.state[item.name]}
									onChange={() => this.onCheckboxButtonClick(ind)}
								/>
								<label
									style={{
										color: item.color,
										fontSize: 12,
										fontWeight: "700",
										marginLeft: 10,
										marginRight: 10
									}}
								>
									{item.label}
								</label>
							</span>
						))}
					</Row>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col className="mb-3 mb-lg-0">
							<Card className="height76">
								{/* {!fetchingPlaces && <GoogleMap markers={markers} mapToggle={mapToggle} />} */}
								{!fetchingPlaces && <GoogleMap markers={markers} mapToggle={false} />}
								{fetchingPlaces && (
									<CardBody className="text-center">
										<Loader type="line-scale-pulse-out-rapid" color="orange" />
									</CardBody>
								)}
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

export default MapModal;
