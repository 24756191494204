import { distance, point, polygon, booleanPointInPolygon } from '@turf/turf';
import { googleService, osmService, PolygonService } from '../../../services';

export const filterOverlappingRedAlerts = (data) => {
    const circles = [];
    let filteredData = {};
    Object.keys(data).forEach((date) => {
        filteredData[date] = {};
        Object.keys(data[date]).forEach((circleId) => {
            const circle = data[date][circleId];
            const { alerts, lat, lng, radius } = circle;
            if (alerts && alerts.length && alerts[0].alert_type === 3) {
                const name = `${date}_${circleId}`;
                circles.push({ name, lat, lng, radius });
            }
        });
    });
    for (let i = 0; i < circles.length; i++) {
        const testCircle = circles[i];
        let matched = false;
        for (let j = 0; j < circles.length; j++) {
            if (i === j) continue;
            const circle = circles[j];
            const p1 = point([circle.lng, circle.lat]);
            const p2 = point([testCircle.lng, testCircle.lat]);
            const d = distance(p1, p2, { units: "meters" });
            if (d <= circle.radius && d <= testCircle.radius) {
                matched = true;
                break;
            }
        }
        if (matched) {
            let [date, circleId] = testCircle.name.split("_");
            filteredData[date][circleId] = data[date][circleId];
        }
    }
    return filteredData;
};

export const getPlaces = (lat, lng, radius, map, callback) => {
    let position = { lat, lng };
    let keywordList = ["school", "store", "mosque", "church", "hindu_temple", "bus_station", "hospital"];
    delayedPlaceSearch({}, keywordList, 0, position, radius, map, async (results) => {
        let osmStations = [];
        try {
            osmStations = await osmService.searchNearby(position, "bus station", radius);
        } catch (e) {}
        let { school: schools, store: markets, mosque, church, hindu_temple, bus_station, hospital: hospitals } = results;
        let mosques = [...mosque, ...church, ...hindu_temple];
        let stations = [...bus_station, ...osmStations];
        callback({ schools, markets, hospitals, mosques, stations });
    });
}

const delayedPlaceSearch = (results, keywordList, index, position, radius, map, callback) => {
    try {
        setTimeout(async () => {
            try {
                results[keywordList[index]] = await googleService.searchNearby(map, position, keywordList[index], radius);
            } catch (e) {
                results[keywordList[index]] = [];
            }
            index++;
            if (index < keywordList.length) delayedPlaceSearch(results, keywordList, index, position, radius, map, callback);
            else callback(results);
        }, 1000);
    } catch (e) {
        callback(results);
    }
};


export const getClusterUC = async (clusters, callback) => {
    try {
        const {
            data: { data }
        } = await PolygonService.getPolygons(localStorage.getItem("currentUser"));
        const polygons = data.map((council) => {
            let { title: name, coordinates } = council;
            coordinates = [coordinates.map((coor) => [coor.lng, coor.lat])];
            return polygon(coordinates, { name });
        });
        
        const unionCouncils = {};

        const dates = Object.keys(clusters);
        dates.forEach((date) => {
            unionCouncils[date] = {}
            const circles = { ...clusters[date] };
            const circleIds = Object.keys(circles);
            circleIds.forEach((circleId) => {
                const { lat, lng } = { ...circles[circleId] };
                const ucName = getPointUC({ lat, lng }, polygons);
                unionCouncils[date][circleId] = ucName;
            });
        });

        callback(unionCouncils);
    }
    catch (error) {}
}

const getPointUC = (coordinates, polygons) => {
    const poi = point([coordinates.lng, coordinates.lat]);
    for (const polygon of polygons) {
        if (booleanPointInPolygon(poi, polygon)) return polygon.properties.name;
    }
    return "";
};