import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const ToolTip = ({ text, target, placement }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);
	return (
		<Tooltip palcement={placement} isOpen={tooltipOpen} target={target} toggle={toggle}>
			{text}
		</Tooltip>
	);
};

export default ToolTip;
