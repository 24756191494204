import React, { Fragment, Component } from "react";
import { Row, Col, Button, CardHeader, Card, CardBody, Progress, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, ButtonGroup } from "reactstrap";
import { XAxis, ResponsiveContainer, BarChart, Bar, Line, Area, AreaChart, ComposedChart, YAxis } from "recharts";

import moment from "moment";
import Loader from "react-loaders";
import MapModal from "../../common/map-modal";
import SweetAlert from "sweetalert-react";

const pValueThreshold = 0.05;
const checkboxes = [
	{
		name: "red",
		key: "red",
		label: "Red",
		color: "#D92550",
		className: "radioRed",
		value: 1
	},
	// {
	// 	name: "yellow",
	// 	key: "yellow",
	// 	label: "Yellow",
	// 	color: "#F7B924",
	// 	className: "radioYellow",
	// 	value: 2
	// },
	// {
	// 	name: "green",
	// 	key: "green",
	// 	label: "Green",
	// 	color: "#3AC47D",
	// 	className: "radioGreen",
	// 	value: 3
	// },
	// {
	// 	name: "blue",
	// 	key: "blue",
	// 	label: "Blue",
	// 	color: "#545CD8",
	// 	className: "radioBlue",
	// 	value: 4
	// }
	{
		name: "orange",
		key: "orange",
		label: "Orange",
		color: "#FF6939",
		className: "radioOrange",
		value: 2
	},
	{
		name: "yellow",
		key: "yellow",
		label: "Yellow",
		color: "#F7B924",
		className: "radioYellow",
		value: 3
	}
];

export default class StatsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "nearby",
			show: false,
			address: "",
			radius: 0,
			vulnerablePopulationData: [],
			tseirForecastData: [],
			currentCount: 0,
			predictedCount: 0,
			shortFallData: [],
			forecastDates: ["11 May", "12 May", "13 May", "14 May", "15 May", "16 May", "17 May"],
			shortFallDates: ["09 May", "10 May", "11 May", "12 May", "13 May", "14 May", "15 May"],
			bedsOffset: null,
			ventilatorsOffset: null,
			population: 2337,
			houseCount: Math.floor(2337 / 6.2),
			bedsFactor: 2.348,
			ventilatorsFactor: 1.493,
			beds: 0,
			ventilators: 0,
			schools: [],
			markets: [],
			mosques: [],
			stations: [],
			hospitals: [],
			showMapModal: false,
			cityFactor: 1,
			pValue: 0,
			selectedAlert: 1,
			alertError: false,
			sendingAlert: false,
			alertErrorMessage: "Your Alert could not be generated!"
		};
		this.TAG = "StatsModal";
		this.alertTypeToAlert = {
			1: <span style={{ color: "#F7B924" }}>Yellow</span>,
			2: <span style={{ color: "#FF6939" }}>Orange</span>,
			3: <span style={{ color: "#D92550" }}>Red</span>,
			0: <span style={{ color: "#000" }}>None</span>
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data && prevProps.data !== this.props.data) {
			let daysUpperLimit = this.props.data.infected ? this.props.data.infected.length - 1 : 6;
			let forecastDates = this.calculateDatesInRangeFromCurrent(0, daysUpperLimit, this.props.data.date ? this.props.data.date : new Date());
			if (this.props.data && this.props.data.infected && this.props.data.infected.length > 0) {
				let shortFallDates = this.calculateDatesInRangeFromCurrent(-2, this.props.data.infected.length - 1 - 2, this.props.data.date ? this.props.data.date : new Date());
				this.setState({ forecastDates, shortFallDates }, () => {
					let population =
						this.props.data.population && this.props.data.population > 0
							? this.props.data.population
							: this.props.data.cases && this.props.data.cases > 0
							? Math.floor(this.props.data.cases * (Math.random() * 123 + 57))
							: 2337;
					let cases = this.props.data.cases ? this.props.data.cases : 279;
					let beds = Math.floor(cases * this.state.bedsFactor);
					let ventilators = Math.floor(cases * this.state.ventilatorsFactor);
					let address = this.props.data.address ? this.props.data.address.replace(",", ", ") : "";
					let ucName = this.props.data.ucName;
					let radius = this.props.data.radius ? this.props.data.radius : 0;
					let schools = this.props.data.schools ? this.props.data.schools : [];
					let markets = this.props.data.markets ? this.props.data.markets : [];
					let mosques = this.props.data.mosques ? this.props.data.mosques : [];
					let stations = this.props.data.stations ? this.props.data.stations : [];
					let hospitals = this.props.data.hospitals ? this.props.data.hospitals : [];
					let vulnerablePopulationData = this.createVulnerablePopulationData(population);
					let tseirForecastData = this.createTseirForecastData(cases, this.props.data.infected);
					let currentCount = cases;
					let predictedCount = Math.round(currentCount + (1 + Math.random()));
					let { shortFallData, bedsOffset, ventilatorsOffset } = this.createShortFallData(cases, beds, ventilators, this.props.data.infected);
					let houseCount = Math.floor(population / 6.2);
					let pValue = this.props.data.pValue ? this.props.data.pValue : 0; //this.props.data.pValue && this.props.data.pValue > 0.05 ? "Moderate" : "Severe";
					let selectedAlert = this.props.data.alerts && this.props.data.alerts.length ? this.props.data.alerts[0].alert_type : 0;
					this.setState({
						address,
						radius,
						currentCount,
						predictedCount,
						vulnerablePopulationData,
						tseirForecastData,
						shortFallData,
						bedsOffset,
						ventilatorsOffset,
						population,
						houseCount,
						beds,
						ventilators,
						schools,
						markets,
						mosques,
						stations,
						hospitals,
						pValue,
						selectedAlert,
						ucName
					});
				});
			}
		}
		if (prevProps.fetchingPlaces !== this.props.fetchingPlaces) {
			let { data } = this.props;
			if (data) {
				let { schools, markets, mosques, stations, hospitals } = data;
				this.setState({
					schools,
					markets,
					mosques,
					stations,
					hospitals
				});
			}
		}
	}

	toggleResourceTab = (tab) => {
		if (this.state.activeTab !== tab) {
			let { beds, ventilators, bedsFactor, ventilatorsFactor } = this.state;
			let cityFactor = 1;
			if (tab === "city") {
				//load city data
				cityFactor = Math.floor(Math.random() * 10) + 10;
				beds = beds * cityFactor;
				ventilators = ventilators * cityFactor;
			} else if (tab === "nearby") {
				let cases = this.props.data.cases ? this.props.data.cases : 279;
				beds = Math.floor(cases * bedsFactor);
				ventilators = Math.floor(cases * ventilatorsFactor);
				//load nearby data
			}
			this.setState({ activeTab: tab, cityFactor, beds, ventilators }, () => {
				this.setState(this.createShortFallData(this.state.currentCount, this.state.beds, this.state.ventilators, this.props.data.infected));
			});
		}
	};

	calculateDatesInRangeFromCurrent = (min, max, date) => {
		try {
			let dates = [];
			let currentDate = new Date(date);
			for (let i = min; i <= max; i++) {
				let previous = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000);
				dates.push(moment(previous).format("DD MMM"));
			}
			return dates;
		} catch (e) {}
	};

	createVulnerablePopulationData = (population) => {
		let targetPercentage = (5.7 + 4.56) / 100;
		let n3 = 0.15455218183320785;
		let n2 = 0.32443849061703185;
		let n1 = 0.6347934306344714;
		let nSum = n1 + n2 + n3;
		let f1 = targetPercentage * (n1 / nSum);
		let f2 = targetPercentage * (n2 / nSum);
		let f3 = targetPercentage * (n3 / nSum);
		let val1 = Math.ceil(population * f1);
		let val2 = Math.ceil(population * f2);
		let val3 = Math.ceil(population * f3);
		return [
			{
				name: "60 - 70",
				Patients1: val1,
				Patients2: null,
				percentage: population * f1
			},
			{
				name: "70 - 80",
				Patients1: val2,
				Patients2: null,
				percentage: population * f2
			},
			{
				name: "80+",
				Patients1: null,
				Patients2: val3,
				percentage: population * f3
			}
		];
	};

	exponentialFunc = (a, b, pow) => {
		return a * Math.pow(b, pow);
	};

	linearFunction = (m, x, c) => {
		return m * x + c;
	};

	createTseirForecastData = (cases, infected = null) => {
		let dates = this.state.forecastDates;
		let tseirForecastData = [];
		if (infected) {
			for (let i = 0; i < dates.length; i++) {
				tseirForecastData.push({
					name: "" + dates[i],
					predicted: infected[i]
				});
			}
		} else {
			let base = cases, //100, //45898, //35000,
				a = 1,
				b = 2,
				f = Math.random() * 2;
			for (let i = 0; i < dates.length; i++) {
				let pow = 0.1 + i;
				tseirForecastData.push({
					name: "" + dates[i],
					predicted: base + this.exponentialFunc(a + f, b, pow)
				});
			}
		}
		return tseirForecastData;
	};

	createShortFallData = (cases, beds, ventilators, infected = null) => {
		let { shortFallDates } = this.state;
		let shortFallData = [];
		let bedsOffset = 0.8;
		let ventilatorsOffset = 0.9;
		if (infected) {
			shortFallData = shortFallDates.map((date, ind) => {
				return {
					name: date,
					beds: beds,
					ventilators: ventilators,
					patients: infected[ind]
				};
			});
		} else {
			let base = cases / 2, //100, //45898, //35000,
				a = 1,
				b = 2,
				f = Math.random() * 2;
			shortFallData = shortFallDates.map((date, ind) => {
				let pow = 0.1 + ind;
				return {
					name: date,
					beds: beds,
					ventilators: ventilators,
					patients: base + this.exponentialFunc(a + f, b, pow) * this.state.cityFactor
				};
			});
		}
		return { shortFallData, bedsOffset, ventilatorsOffset };
	};

	gradientOffset = (data, attr) => {
		const dataMax = Math.max(...data.map((i) => i[attr]));
		const dataMin = Math.min(...data.map((i) => i[attr]));
		// return 0.8;
		if (dataMax <= 0) {
			return 0;
		} else if (dataMin >= 0) {
			return 1;
		} else {
			return dataMax / (dataMax - dataMin);
		}
	};

	patientsPredictionIntensitySection = () => {
		try {
			let { currentCount, tseirForecastData } = this.state;
			let {data} = this.props;
			let alert_type = 1;
			if (data && data.alerts && data.alerts.length > 0) {
				alert_type = data.alerts[0].alert_type;
			}
			return (
				<CardBody>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<Row className="no-gutters">
										<Col sm="6" md="4" xl="4">
											<div className="card no-shadow rm-border bg-transparent widget-chart text-left">
												<div className="icon-wrapper rounded-circle">
													<div className="icon-wrapper-bg opacity-10 bg-warning" />
													<i className="fas fa-user-injured text-dark opacity-8" />
												</div>
												<div className="widget-chart-content">
													<div className="widget-subheading">No. of Patients</div>
													<div className="widget-numbers">{currentCount}</div>
												</div>
											</div>
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
										<Col sm="6" md="4" xl="4">
											<div className="card no-shadow rm-border bg-transparent widget-chart text-left">
												<div className="icon-wrapper rounded-circle">
													<div className="icon-wrapper-bg opacity-9 bg-danger" />
													<i className="fas fa-hospital-user text-white" />
												</div>
												<div className="widget-chart-content">
													<div className="widget-subheading">Predicted Patients</div>
													<div className="widget-numbers">
														{tseirForecastData.length > 0 ? Math.floor(tseirForecastData[tseirForecastData.length - 1].predicted) : 0}
													</div>
													<div className="widget-description opacity-8 text-focus">+ 5 days</div>
												</div>
											</div>
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
										<Col sm="6" md="4" xl="4">
											<div className="card no-shadow rm-border bg-transparent widget-chart text-left">
												<div className="icon-wrapper rounded-circle">
													<div className="icon-wrapper-bg opacity-9 bg-success" />
													<i className="fas fa-procedures text-white" />
												</div>
												<div className="widget-chart-content">
													<div className="widget-subheading">Intensity</div>
													{alert_type < 3 && (
														<div className="widget-numbers text-warning">
															<small>Moderate</small>
														</div>
													)}
													{alert_type >= 3 && (
														<div className="widget-numbers text-danger">
															<small>Severe</small>
														</div>
													)}
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</CardBody>
			);
		} catch (e) {}
	};

	vulnerableTseirStatisticsSection = () => {
		let { vulnerablePopulationData, population, houseCount, tseirForecastData, currentCount } = this.state;
		return (
			<CardBody>
				<Row>
					<Col lg="12" xl="6">
						<Card className="main-card mb-3">
							<CardBody>
								<CardTitle>Vulnerable Population Statistics</CardTitle>
								<ResponsiveContainer height={244}>
									<BarChart data={vulnerablePopulationData}>
										<XAxis dataKey="name" />
										<YAxis dataKey="Patients1" />
										<Bar barGap="12" dataKey="Patients1" stackId="a" fill="#545CD8" />
										<Bar barGap="12" dataKey="Patients2" stackId="a" fill="#D92550" />
									</BarChart>
								</ResponsiveContainer>
								<Row className="mt-3">
									<Col sm="12" md="6">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-numbers text-dark">{population}</div>
													</div>
												</div>
												<div className="widget-progress-wrapper mt-1">
													<Progress className="progress-bar-xs progress-bar-animated-alt" color="info" value="65" />
													<div className="progress-sub-label">
														<div className="sub-label-left font-size-md">Population</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col sm="12" md="6">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-numbers text-dark">{houseCount}</div>
													</div>
												</div>
												<div className="widget-progress-wrapper mt-1">
													<Progress className="progress-bar-xs progress-bar-animated-alt" color="warning" value="22" />
													<div className="progress-sub-label">
														<div className="sub-label-left font-size-md">House Holds</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col lg="12" xl="6">
						<Card className="main-card mb-4">
							<CardBody>
								<CardTitle>TSEIR Forecast</CardTitle>
								<ResponsiveContainer height={244}>
									<ComposedChart data={tseirForecastData}>
										<XAxis dataKey="name" />
										<YAxis dataKey="predicted" />

										<Line dataKey="predicted" stroke="#D92550" strokeDasharray="3 3" />
									</ComposedChart>
								</ResponsiveContainer>
								<Row className="mt-3">
									<Col sm="12" md="6">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div
															className="widget-numbers"
															style={{
																color: "#545CD8"
															}}
														>
															{currentCount}
														</div>
													</div>
												</div>
												<div className="widget-progress-wrapper mt-1">
													<div className="progress-sub-label">
														<div
															className="sub-label-left font-size-md"
															style={{
																color: "#545CD8"
															}}
														>
															Current
														</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col sm="12" md="6">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div
															className="widget-numbers"
															style={{
																color: "#D92550"
															}}
														>
															{tseirForecastData.length > 0 ? Math.floor(tseirForecastData[tseirForecastData.length - 1].predicted) : 0}
														</div>
													</div>
												</div>
												<div className="widget-progress-wrapper mt-1">
													<div className="progress-sub-label">
														<div
															className="sub-label-left font-size-md"
															style={{
																color: "#D92550"
															}}
														>
															Predicted
														</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</CardBody>
		);
	};

	placesBlocksSection = () => {
		let { schools, markets, mosques, stations, hospitals } = this.state;
		let { fetchingPlaces } = this.props;
		return (
			<CardBody>
				<Row>
					<Col lg="12" xl="12">
						<Card className="main-card mb-3">
							<div className="grid-menu grid-menu-2col">
								<Row className="no-gutters" style={{ justifyContent: "center" }}>
									<Col sm="4">
										<div className="widget-chart widget-chart-hover" onClick={this.toggleMapModal}>
											<div className="icon-wrapper rounded-circle">
												<div className="icon-wrapper-bg" style={{ background: "#F7B92499" }} />
												<i className="fas fa-store-alt" style={{ color: "#F7B924" }} />
											</div>
											{!fetchingPlaces && <div className="widget-numbers">{markets.length}</div>}
											{fetchingPlaces && (
												<div className="widget-numbers">
													<Loader type="line-scale-pulse-out-rapid" color="orange" />
												</div>
											)}
											<div className="widget-subheading">Shopping Areas</div>
										</div>
									</Col>
									<Col sm="4">
										<div className="widget-chart widget-chart-hover" onClick={this.toggleMapModal}>
											<div className="icon-wrapper rounded-circle">
												<div className="icon-wrapper-bg" style={{ background: "#f65ca299" }} />
												<i className="fas fa-hospital" style={{ color: "#f65ca2" }} />
											</div>
											{!fetchingPlaces && <div className="widget-numbers">{hospitals.length}</div>}
											{fetchingPlaces && (
												<div className="widget-numbers">
													<Loader type="line-scale-pulse-out-rapid" color="orange" />
												</div>
											)}
											<div className="widget-subheading">Hospitals</div>
										</div>
									</Col>
									<Col sm="4">
										<div className="widget-chart widget-chart-hover" onClick={this.toggleMapModal}>
											<div className="icon-wrapper rounded-circle">
												<div className="icon-wrapper-bg" style={{ background: "#3AC47D99" }} />
												<i className="fas fa-mosque" style={{ color: "#3AC47D" }} />
											</div>
											{!fetchingPlaces && <div className="widget-numbers">{mosques.length}</div>}
											{fetchingPlaces && (
												<div className="widget-numbers">
													<Loader type="line-scale-pulse-out-rapid" color="orange" />
												</div>
											)}
											<div className="widget-subheading">Places of Worship</div>
										</div>
									</Col>
								</Row>

								<Row className="no-gutters" style={{ justifyContent: "center" }}>
									<Col sm="4">
										<div className="widget-chart widget-chart-hover" onClick={this.toggleMapModal}>
											<div className="icon-wrapper rounded-circle">
												<div className="icon-wrapper-bg" style={{ background: "#D9255099" }} />
												<i className="fas fa-school" style={{ color: "#D92550" }} />
											</div>
											{!fetchingPlaces && <div className="widget-numbers">{schools.length}</div>}
											{fetchingPlaces && (
												<div className="widget-numbers">
													<Loader type="line-scale-pulse-out-rapid" color="orange" />
												</div>
											)}
											<div className="widget-subheading">Educational Institutes</div>
										</div>
									</Col>
									<Col sm="4">
										<div className="widget-chart widget-chart-hover" onClick={this.toggleMapModal}>
											<div className="icon-wrapper rounded-circle">
												<div className="icon-wrapper-bg" style={{ background: "#545CD899" }} />
												<i className="fas fa-bus-alt" style={{ color: "#545CD8" }} />
											</div>
											{!fetchingPlaces && <div className="widget-numbers">{stations.length}</div>}
											{fetchingPlaces && (
												<div className="widget-numbers">
													<Loader type="line-scale-pulse-out-rapid" color="orange" />
												</div>
											)}
											<div className="widget-subheading">Bus Stations</div>
										</div>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</CardBody>
		);
	};

	resourcesStatisticsSection = () => {
		let { activeTab, shortFallData, beds, ventilators, bedsOffset, ventilatorsOffset } = this.state;
		return (
			<CardBody>
				<Card>
					<CardHeader className="border-top bg-light">
						<h5 className="menu-header-title">Available Resources</h5>
					</CardHeader>
					<CardBody>
						<Row>
							<Col>
								<div className="actions-icon-btn mx-auto text-center">
									<div tabs="true">
										<ButtonGroup size="lg">
											<Button
												size="sm"
												color="focus"
												active={activeTab === "nearby"}
												className="btn-pill"
												onClick={() => {
													this.toggleResourceTab("nearby");
												}}
											>
												Nearby
											</Button>
											<Button
												size="sm"
												color="focus"
												active={activeTab === "city"}
												className="btn-pill"
												onClick={() => {
													this.toggleResourceTab("city");
												}}
											>
												City
											</Button>
										</ButtonGroup>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col md="6" sm="12">
								<Card className="card-hover-shadow-2x mb-sm-3 mb-md-0 widget-chart widget-chart2  text-left">
									<div className="widget-chart-content text-white">
										<div className="widget-chart-flex">
											<div className="widget-title text-dark">Available Beds</div>
										</div>
										<div className="widget-chart-flex">
											<div className="widget-numbers text-success">
												{beds}
												<small> Units</small>
											</div>
										</div>
									</div>
								</Card>
							</Col>
							<Col md="6" sm="12">
								<Card className="card-hover-shadow-2x widget-chart widget-chart2  text-left">
									<div className="widget-chart-content text-white">
										<div className="widget-chart-flex">
											<div className="widget-title text-dark">Available Ventilators</div>
										</div>
										<div className="widget-chart-flex">
											<div className="widget-numbers text-warning">
												{ventilators}
												<small> Units</small>
											</div>
										</div>
									</div>
								</Card>
							</Col>
						</Row>
						<Card className="mt-3">
							<CardBody>
								<Row>
									<Col>
										<div>
											<h5 className="menu-header-title mt-3">Potential Shortfall</h5>
										</div>
									</Col>
								</Row>
								<Row className="mt-3">
									<Col md="6" sm="12">
										<Card className="card-border mb-sm-3 mb-md-0 border-light no-shadow">
											<div className="widget-content">
												<div className="widget-content-outer">
													<div className="menu-header-title mb-4">
														<small>Patients vs Beds</small>
													</div>
													<div className="widget-content-wrapper">
														<ResponsiveContainer height={244}>
															<AreaChart data={shortFallData}>
																<XAxis dataKey="name" />
																<YAxis dataKey="patients" />
																<defs>
																	<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
																		<stop offset={bedsOffset} stopColor="#82ca9d" stopOpacity={1} />
																		<stop offset={bedsOffset} stopColor="#ffffff" stopOpacity={1} />
																	</linearGradient>
																</defs>
																<Area type="monotone" dataKey="patients" stroke="#82ca9d" fill="url(#splitColor)" />
															</AreaChart>
														</ResponsiveContainer>
													</div>
												</div>
											</div>
										</Card>
									</Col>
									<Col md="6" sm="12">
										<Card className="card-border border-light no-shadow">
											<div className="widget-content">
												<div className="widget-content-outer">
													<div className="menu-header-title mb-4">
														<small>Patients vs Ventilators</small>
													</div>
													<div className="widget-content-wrapper">
														<ResponsiveContainer height={244}>
															<AreaChart data={shortFallData}>
																<XAxis dataKey="name" />
																<YAxis dataKey="patients" />
																<defs>
																	<linearGradient id="splitColor2" x1="0" y1="0" x2="0" y2="1">
																		<stop offset={ventilatorsOffset} stopColor="#ffc658" stopOpacity={1} />
																		<stop offset={ventilatorsOffset} stopColor="#ffffff" stopOpacity={1} />
																	</linearGradient>
																</defs>
																<Area type="monotone" dataKey="patients" stroke="#ffc658" fill="url(#splitColor2)" />
															</AreaChart>
														</ResponsiveContainer>
													</div>
												</div>
											</div>
										</Card>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</CardBody>
				</Card>
			</CardBody>
		);
	};

	generateAlertSection = () => {
		let { selectedAlert } = this.state;
		return (
			<CardBody>
				<Card>
					<CardTitle className="m-3">Generated Alert: {this.alertTypeToAlert[selectedAlert]}</CardTitle>
				</Card>
			</CardBody>
		);
	};

	generateAlertSectionOld = () => {
		let { handleSendAlert } = this.props;
		return (
			<CardBody>
				<Card>
					<CardBody>
						<h5 className="menu-header-title mt-4">Generate Alert</h5>
						<div
							className="my-3"
							style={{
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							{checkboxes.map((item) => (
								<Fragment>
									<div className="radioItem mr-3">
										<input
											// name={item.name}
											name="alert"
											key={item.key}
											type="radio"
											id={item.key}
											checked={this.state.selectedAlert === item.value}
											onChange={() => this.setState({ selectedAlert: item.value })}
										/>
										<label
											style={{
												color: item.color
											}}
											for={item.key}
											className={item.className}
										>
											{item.label}
										</label>
									</div>
								</Fragment>
							))}
						</div>
						<Row
							style={{
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<Col sm={12} md={4}></Col>
							<Col sm={12} md={4}>
								{!this.state.sendingAlert && (
									<Button color="primary" className="btn-pill text-center py-3" size="lg" block={true} onClick={this.handleSendAlert}>
										<span className="h5 mb-0">Generate Alert</span>
									</Button>
								)}
								{this.state.sendingAlert && (
									<Button color="primary" className="btn-pill text-center py-3" size="lg" block={true}>
										<Loader type="line-scale-pulse-out-rapid" color="black" />
									</Button>
								)}
								<SweetAlert
									title="Successful!"
									confirmButtonColor=""
									show={this.state.show}
									text="Your Alert has been generated!"
									type="success"
									onConfirm={() =>
										this.setState({
											show: false
										})
									}
								/>
								<SweetAlert
									title="Failed!"
									confirmButtonColor=""
									show={this.state.alertError}
									text={this.state.alertErrorMessage}
									type="error"
									onConfirm={() =>
										this.setState({
											alertError: false
										})
									}
								/>
							</Col>
							<Col sm={12} md={4}></Col>
						</Row>
					</CardBody>
				</Card>
			</CardBody>
		);
	};

	handleSendAlert = () => {
		try {
			if (this.props.fetchingPlaces) {
				this.setState({ alertError: true, alertErrorMessage: "Cannot send alert until all places are fetched" });
			} else {
				this.setState({ sendingAlert: true }, async () => {
					let output = await this.props.handleSendAlert(this.state.selectedAlert, this.props.data);
					if (output) this.setState({ show: true, sendingAlert: false });
					else this.setState({ alertError: true, sendingAlert: false, alertErrorMessage: "Server Error: Your Alert could not be generated!" });
				});
			}
		} catch (e) {
			this.logger(e);
		}
	};

	toggleMapModal = () => {
		let { showMapModal } = this.state;
		showMapModal = !showMapModal;
		this.setState({ showMapModal });
	};

	logger() {
		let args = Array.from(arguments);
	}

	render() {
		const { visible, toggleModal } = this.props;
		const { address, radius, ucName } = this.state;

		return (
			<Fragment>
				<MapModal
					showMapModal={this.state.showMapModal}
					toggleMapModal={this.toggleMapModal}
					schools={this.state.schools}
					markets={this.state.markets}
					mosques={this.state.mosques}
					stations={this.state.stations}
					hospitals={this.state.hospitals}
					fetchingPlaces={this.props.fetchingPlaces}
				/>
				<Modal isOpen={visible} toggle={toggleModal} showMask={false} size="lg" backdrop={true} keyboard={true}>
					<ModalHeader toggle={toggleModal}>
						{address}
						<br></br>
						<small>{ucName}</small>
						<br></br>
						<small>
							<em>(Radius: {radius} m)</em>
						</small>
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "#ececec" }}>
						<Card>
							{this.generateAlertSection()}
							{this.patientsPredictionIntensitySection()}
							{this.vulnerableTseirStatisticsSection()}
							{this.placesBlocksSection()}
							{this.resourcesStatisticsSection()}
							{/* {this.generateAlertSectionOld()} */}
						</Card>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={toggleModal}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}
}
