import Axios from 'axios';
import Auth from './auth';

Axios.defaults.headers = {
    Authorization: `${Auth.getAuthorizationToken()}`
}

export default {
    get: Axios.get,
    post: Axios.post,
    put: Axios.put,
    patch: Axios.patch,
    delete: Axios.delete
};