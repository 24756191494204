import React from "react";
import { CardHeader, Card, ListGroup, ListGroupItem } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMarker, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
let mData = [
  { area: "I-8", radius: "50m", patients: "25" },
  { area: "H-2", radius: "50m", patients: "25" },
  { area: "G-11", radius: "50m", patients: "25" },
  { area: "G-11/2", radius: "50m", patients: "25" },
  { area: "G-11/3", radius: "50m", patients: "25" },
  { area: "F-12", radius: "50m", patients: "25" },
  { area: "E-6", radius: "50m", patients: "25" },
  { area: "Kashmir Highway", radius: "50m", patients: "25" },
  { area: "Peer Sohawa", radius: "50m", patients: "25" },
];

const getCircleColorBasedOnAlert = (circle) => {
  let alertColors = {
    1: "#FFDD0033",
    2: "#FF550033",
    3: "#FF000033",
    0: "#3AC47D33",
  };
  if (circle.alerts && circle.alerts.length)
    return alertColors[circle.alerts[0].alert_type];
  else return alertColors[0];
};

const ListView = ({ data }) => {
  let dataList = [];
  if (data) {
    let keys = Object.keys(data);
    dataList = keys.map((key) => {
      return data[key];
    });
  } else dataList = mData;
  dataList.sort((a, b) => {
    if (!(a.alerts && a.alerts.length)) return -1;
    if (!(b.alerts && b.alerts.length)) return Number.MAX_SAFE_INTEGER;
    return a.alerts[0].alert_type - b.alerts[0].alert_type;
  });
  dataList.reverse();
  return (
    <Card className="card-hover-shadow-2x h100">
      <CardHeader className="card-header-tab">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i className="header-icon lnr-database icon-gradient bg-malibu-beach">
            {" "}
          </i>
          List view
        </div>
      </CardHeader>
      <div className="scroll-area-lg1 scrollableListings">
        <PerfectScrollbar>
          <div className="p-2">
            <ListGroup className="todo-list-wrapper" flush>
              {dataList ? (
                dataList.map((item, ind) => {
                  let color = getCircleColorBasedOnAlert(item);
                  return (
                    <ListGroupItem
                      style={{ background: color }}
                      key={`ListGroupItem_item_${ind}`}
                    >
                      <div
                        className="todo-indicator"
                        style={{ background: color.slice(0, 7) }}
                      />
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <div className="widget-content-left">
                              {/* <FontAwesomeIcon name={faMapMarkerAlt} size={50} color="#000" /> */}
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {item.address}
                              </div>
                              <div className="widget-subheading">
                                {item.ucName}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="widget-subheading">
                                Radius: {item.radius} m
                              </div>
                              <div className="widget-content-right">
                                Cases: {item.cases}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  );
                })
              ) : (
                <div>No Submission in this area</div>
              )}
            </ListGroup>
          </div>
        </PerfectScrollbar>
      </div>
    </Card>
  );
};

export default ListView;
