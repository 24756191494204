import React, { Fragment } from "react";
import cx from "classnames";
// import Ionicon from 'react-ionicons';
import { IoIosGrid, IoIosNotificationsOutline } from "react-icons/io";
import { FiUsers } from "react-icons/fi";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, Button, NavItem, ListGroup, ListGroupItem, Row, Col } from "reactstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
// import city3 from "../../../architectuiassets/utils/images/dropdown-header/city3.jpg";

// import Tabs from "react-responsive-tabs";

// Dropdown Tabs Content
import ChatExample from "./TabsContent/ChatExample";
import TimelineEx from "./TabsContent/TimelineExample";
import SysErrEx from "./TabsContent/SystemExample";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "../../../services";
// import Select from "react-select";

const tabsContent = [
	{
		title: "Messages",
		content: <ChatExample />
	},
	{
		title: "Events",
		content: <TimelineEx />
	},
	{
		title: "System Errors",
		content: <SysErrEx />
	}
];

function getTabs() {
	return tabsContent.map((tab, index) => ({
		title: tab.title,
		getContent: () => tab.content,
		key: index
	}));
}

class HeaderDots extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			checked: false,
			isToggleOn: true,
			start_date: null,
			end_date: null,
			selectedUser: {},
			userOptions: []
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	handleChange(checked) {
		this.setState({ checked });
	}

	handleClick() {
		this.setState(function(prevState) {
			return { isToggleOn: !prevState.isToggleOn };
		});
	}

	onDatesChange = async ({ startDate, endDate }) => {
		await this.setState({
			start_date: startDate,
			end_date: endDate
		});
	};

	async componentDidMount() {
		let users = await Auth.getPortalUsers();
		let currentUser = await localStorage.getItem("currentUser");
		if (users && users.length !== 0) {
			let portalUsers = users;
			portalUsers.map((item) => {
				this.setState({ userOptions: [...this.state.userOptions, { value: item._id, label: item.first_name + " " + item.last_name }] }, () =>
					this.state.userOptions.forEach((item) => {
						if (item.value === currentUser) {
							this.setState({ selectedUser: item });
						}
					})
				);
			});
		}
	}

	handleChange = (selectedOption) => {
		localStorage.setItem("currentUser", selectedOption.value);
		this.setState({ selectedOption }, () => window.location.reload());
	};

	render() {
		const { showDateSelector } = this.props;
		return (
			<Fragment>
				<div className="header-dots">
					{showDateSelector && (
						<div className="mx-1">
							<DateRangePicker
								startDate={this.props.top.start_date}
								endDate={this.props.top.end_date}
								onDatesChange={this.props.changeDates}
								focusedInput={this.state.focusedInput}
								onFocusChange={(focusedInput) => this.setState({ focusedInput })}
								numberOfMonths={1}
								isOutsideRange={() => false}
								// isOutsideRange={(day) => day > moment()}
								// showClearDates
								small
								customInputIcon={
									<div className="icon-wrapper icon-wrapper-alt rounded-circle">
										<div className="icon-wrapper-bg bg-primary" />
										<IoIosGrid color="#ff6939" fontSize="18px" />
									</div>
								}
								noBorder
							/>
						</div>
					)}

					{/* <UncontrolledDropdown>
            <DropdownToggle className="p-0 mr-2" color="link">
              <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-danger" />
                <FiUsers color="#d92550" fontSize="18px" />
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <div className="dropdown-menu-header mb-0">
                <div className="menu-header-content text-dark">
                  <ListGroup>
                    {portalUsers.map((item, index) => (
                      <ListGroupItem>
                        <div style={{ background: item._id === localStorage.getItem("currentUser") ? "#e3e3e3" : "" }}>
                          <a
                            href={""}
                            onClick={() => {
                              localStorage.removeItem("currentUser");
                              localStorage.setItem("currentUser", item._id);
                            }}
                          >
                            <Row style={{ justifyContent: "center", alignItems: "center", marginLeft: 10 }}>
                              <FontAwesomeIcon icon={faUser} />
                              <Col style={{ marginLeft: 20 }}>
                                <Row>{item.firstName + " " + item.lastName}</Row>
                                <Row>{item.company_name}</Row>
                              </Col>
                            </Row>
                          </a>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown> */}
				</div>
			</Fragment>
		);
	}
}

export default HeaderDots;
