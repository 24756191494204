import React from "react";
import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from "reactstrap";
import { Pagination } from "../Pagination";

const handleChangePage = () => {
  "";
};

export const TimelineTable = ({ data, tableColumns, title }) => {
  return (
    <Row className="mt-3">
      <Col md="12">
        <Card className="main-card mb-3">
          <CardHeader>{title}</CardHeader>
          <Table
            responsive
            hover
            striped
            borderless
            className="align-middle mb-0"
          >
            <thead>
              <tr>
                {tableColumns.map((item) => (
                  <th className="text-center">{item.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map((item, index) => (
                  <tr>
                    <td className="text-center text-muted">{item.id}</td>
                    <td>
                      <div className="text-center">
                        <img
                          width={45}
                          height={40}
                          className="rounded-circle"
                          src={item.thumbnail}
                          alt=""
                        />
                      </div>
                      {/* <div className="widget-content-left flex2">
                            <div className="widget-heading">
                              {item.circleName}
                            </div>
                            <div className="widget-subheading opacity-7">
                              {item.radius}
                            </div>
                          </div> */}
                    </td>
                    <td className="text-center">{item.circleName}</td>
                    <td className="text-center">{item.radius}</td>
                    <td className="text-center">{item.enumName}</td>
                    <td className="text-center">
                      <div className={item.class}>{item.alertType}</div>
                    </td>
                    <td className="text-center">
                      <div className="badge badge-warning">
                        {item.compliance}
                      </div>
                    </td>
                    {/* <td className="text-center">
                      <Button size="sm" color="primary">
                        Details
                      </Button>
                    </td> */}
                  </tr>
                ))
              ) : (
                <div className="text-center col-12 align-content-center align-items-center">
                  <h4 className="align-self-center">No Submissions</h4>
                </div>
              )}
            </tbody>
          </Table>
          <CardFooter className="d-block text-center">
            {/* <Button className="btn-wide" color="success">
							Load More
						</Button> */}
            <Row className="justify-content-center mb-n3">
              <Pagination pageCount={10} handlePageClick={handleChangePage()} />
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};
