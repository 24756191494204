import React, { Fragment } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledButtonDropdown,
} from "reactstrap";
import HeaderLogo from "../AppLogo/index";
import SearchBox from "./Components/SearchBox";
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";
import HeaderDots from "./Components/HeaderDots";
import { Button } from "reactstrap";
import SALogo from "../../assets/images/logo.svg";
import Select from "react-select";
import { Auth } from "../../services";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedUser: {},
      userOptions: [],
    };
  }

  async componentDidMount() {
    let users = await Auth.getPortalUsers();
    let currentUser = await localStorage.getItem("currentUser");
    if (users && users.length !== 0) {
      let portalUsers = users;
      portalUsers.map((item) => {
        this.setState(
          {
            userOptions: [
              ...this.state.userOptions,
              {
                value: item._id,
                label: item.first_name + " " + item.last_name,
              },
            ],
          },
          () =>
            this.state.userOptions.forEach((item) => {
              if (item.value === currentUser) {
                this.setState({ selectedUser: item });
              }
            })
        );
      });
    }
  }

  handleChange = (selectedOption) => {
    localStorage.setItem("currentUser", selectedOption.value);
    this.setState({ selectedOption }, () => window.location.reload());
  };

  render() {
    let {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
      showProductsDropdown,
      showDateSelector,
      handleMicroSurveyClick,
    } = this.props;
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo
            isToggleOn={this.props.top.isToggleOn}
            toggleSwitch={this.props.toggleSwitch}
          />
          <div className="logo">
            <a href="/" className="">
              <img src={SALogo} />
            </a>
          </div>
          <div
            className={cx("app-header__content", {
              "header-mobile-open": enableMobileMenuSmall,
            })}
          >
            {showProductsDropdown && (
              <div className="dropdownSelect">
                <SearchBox
                  top={this.props.top}
                  onProductChange={this.props.onProductChange}
                />
              </div>
            )}
            <div className="desktopShown">
              <MegaMenu
                menuItems={this.props.menuItems}
                top={this.props.top.topTab}
                onChangeTopTabs={this.props.onChangeTopTabs}
              />
            </div>

            <div className="dropdownSelection">
              <Select
                value={this.state.selectedUser}
                onChange={(option) => this.handleChange(option)}
                options={this.state.userOptions}
              />
              {/* <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  Link
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <ul>
                    <li>a</li>
                    <li>b</li>
                    <li>c</li>
                  </ul>
                </DropdownMenu>
              </UncontrolledButtonDropdown> */}
            </div>
            <div className="app-header-left ml-auto">
              <div className="app-header-right">
                <HeaderDots
                  top={this.props.top}
                  toggleSwitch={this.props.toggleSwitch}
                  changeDates={this.props.changeDates}
                  showDateSelector={showDateSelector}
                />
              </div>
              <UserBox />
            </div>
          </div>
          <div className="essentialMenuBar">
            <MegaMenu
              menuItems={this.props.menuItems}
              top={this.props.top.topTabs}
              onChangeTopTabs={this.props.onChangeTopTabs}
            />
          </div>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}
Header.defaultProps = {
  showProductsDropdown: true,
  showDateSelector: true,
};
const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
