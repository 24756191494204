import React, { Component, Fragment } from "react";
import Header from "../../../layout/AppHeader/index";
import AppSideBar from "../../../layout/AppSidebar/index";
import ResizeDetector from "react-resize-detector";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
// import Main from "./main";
import Analysis from "./Analysis/Analysis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import ScrollUpButton from "react-scroll-up-button";
// import HdData from "../data/hd_data.json";
// import TabData from "../data/tabular_data.json";
import Monitor from "./Monitor";
import { Tracking } from "./Tracking/index";
import { Lockdown } from './Lockdown';
import { Auth } from "../../../services";
import { toast } from "react-toastify";

const userTypes = ["Commercial", "Residential", "Student"];
const userSegments = ["A", "B", "C", "D"];
class CovidLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			leftTab: "",
			top: {
				topTab: 0,
				isToggleOn: true
			},
			selectedTypes: [],
			selectedSegments: [],
			selectedMenu: null,
			selectedFilter: null,
			menuItems: {
				top: [
					{ label: "Analysis", icon: "fas fa-star" },
					{ label: "Alert Monitor", icon: "fas fa-bullhorn" },
					{ label: "Contact Tracing", icon: "fas fa-map-pin" },
					{ label: "Lockdown Tracking", icon: "fas fa-lock" }

				],
				left: [
					{
						icon: "fas fa-users",
						label: "IDIMS"
					},
					{
						icon: "fa fa-layer-group",
						label: "RMS"
					},
					{
						icon: "fas fa-map-marker-alt",
						label: "Location Tracking"
					},
					{
						icon: "fas fa-flask",
						label: "Laboratory Reports"
					}
				]
			},
			isPolygonSelected: false,
			selectedPolygon: null
		};
	}

	async componentWillMount() {
		let auth = Auth.getAuthorizationToken();
		if (!auth) {
			this.props.history.push("/login");
		} else {
			try {
				let userProfiles = await Auth.getUserProfiles();
			} catch (error) {
				toast.error("Cannot find user profiles");
			}
		}
	}

	_onChangeTopTabs = (tabId) => {
		const topTab = tabId === this.state.top.topTab ? null : tabId;
		this.setState({
			top: { ...this.state.top, topTab },
			selectedMenu: null
		});
	};

	_toggleInnerViews = () => {
		const {
			selectedTypes,
			selectedSegments,
			selectedMenu,
			selectedFilter,
			top: { topTab, isToggleOn }
		} = this.state;
		if (this.state.top.topTab === 0) {
			return (
				<Analysis />
			);
		} else if (this.state.top.topTab === 1) {
			return (
				<Monitor
					selectedTypes={selectedTypes}
					selectedSegments={selectedSegments}
					selectedTab={topTab}
					selectedMenu={selectedMenu}
					selectedFilter={selectedFilter}
					showAnalysis={isToggleOn}
					handleSelectedPolygon={this.handleSelectedPolygon}
				/>
			);
		} else if (this.state.top.topTab === 2) {
			return <Tracking />;
		}
		else if (topTab === 3) return <Lockdown />
	};

	handleChangeSelectedOptions = (option, options) => {
		if (options.includes(option)) options = options.filter((selection) => selection !== option);
		else options.push(option);
		return options;
	};

	handleChangeUserSegment = (segment) => {
		let selectedSegments = this.handleChangeSelectedOptions(segment, [...this.state.selectedSegments]);
		this.setState({
			selectedSegments,
			selectedTypes: [],
			selectedFilter: selectedSegments.length ? "segment" : null
		});
	};

	handleChangeUserType = (type) => {
		let selectedTypes = this.handleChangeSelectedOptions(type, [...this.state.selectedTypes]);
		this.setState({
			selectedTypes,
			selectedSegments: [],
			selectedFilter: selectedTypes.length ? "type" : null,
			top: { ...this.state.top, topTab: null }
		});
	};

	_onChangeLeftMenu = (itemId) => {
		if (!itemId) return;
		let field = itemId.replace(" ", "");
		if (userTypes.includes(field)) this.handleChangeUserType(field);
		else if (userSegments.includes(field)) this.handleChangeUserSegment(field);
		else {
			field = field.toLowerCase();
			const selectedMenu = this.state.selectedMenu === field ? null : field;
			this.setState({
				selectedMenu,
				top: { ...this.state.top, topTab: null }
			});
		}
		if (this.state.leftTab === itemId.toLowerCase()) this.setState({ leftTab: null });
		else this.setState({ leftTab: itemId.toLowerCase() });
	};

	createCheckboxMenu = (options, selectedOptions, onClickHandler) => {
		return options.map((option) => ({
			label: (
				<span onClick={(_) => onClickHandler(option)}>
					<FontAwesomeIcon icon={selectedOptions.includes(option) ? faCheckSquare : faSquare} /> {option}
				</span>
			)
		}));
	};

	handleSelectedPolygon = (type, id) => {
		try {
			if (type && id) {
				let { selectedPolygon } = this.state;
				if (type === "voronoi") {
					selectedPolygon = id ? parseInt(id) : selectedPolygon;
				}
				this.setState({ isPolygonSelected: true, selectedPolygon });
			} else {
				this.setState({ isPolygonSelected: false });
			}
		} catch (e) {}
	};

	// handleMicroSurveyClick = () => {
	//   let { isPolygonSelected, selectedTypes, selectedSegments, selectedPolygon } = this.state;
	//   if (selectedPolygon !== null) {
	//     let markers = [HdData[selectedPolygon].poi];
	//     if (selectedSegments.length > 0 || selectedTypes.length > 0) {
	//       for (const data of TabData) {
	//         let match = false;
	//         for (const segment of selectedSegments) {
	//           if (parseInt(data.polygon) === selectedPolygon && data.grade.toLowerCase() === segment.toLowerCase()) {
	//             match = true;
	//             break;
	//           }
	//         }
	//         if (!match) {
	//           for (const type of selectedTypes) {
	//             if (parseInt(data.polygon) === selectedPolygon && data.type.toLowerCase() === type.toLowerCase()) {
	//               match = true;
	//               break;
	//             }
	//           }
	//         }
	//         if (match) markers.push({ lat: data.lat, lng: data.lon });
	//       }
	//     }
	//     window.open(process.env.REACT_APP_SURVEY_AUTO_COMPOSER_FRONTEND_JAZZ + "?markers=" + encodeURI(JSON.stringify(markers)) + "&authenticated=true", "_blank");
	//   }
	// };

	render() {
		let { colorScheme, enableFixedHeader, enableFixedSidebar, enableFixedFooter, enableClosedSidebar, closedSmallerSidebar, enableMobileMenu, enablePageTabsAlt } = this.props;

		const { menuItems, top, selectedTypes, selectedSegments } = this.state;
		// const modifiedMenu = { ...menuItems };
		// const left = [...modifiedMenu.left];
		// const typesOption = { ...left[2] };
		// typesOption.content = this.createCheckboxMenu(
		//   userTypes,
		//   selectedTypes,
		//   this.handleChangeUserType
		// );
		// left[2] = typesOption;

		// const segmentsOption = { ...left[3] };
		// segmentsOption.content = this.createCheckboxMenu(
		//   userSegments,
		//   selectedSegments,
		//   this.handleChangeUserSegment
		// );
		// left[3] = segmentsOption;

		// modifiedMenu.left = left;

		return (
			<ResizeDetector
				handleWidth
				render={({ width }) => (
					<Fragment>
						<div
							className={cx(
								"app-container app-theme-" + colorScheme,
								{ "fixed-header": enableFixedHeader },
								{
									"fixed-sidebar": enableFixedSidebar || width < 1250
								},
								{ "fixed-footer": enableFixedFooter },
								{
									"closed-sidebar": enableClosedSidebar || width < 1250
								},
								{
									"closed-sidebar-mobile": closedSmallerSidebar || width < 1250
								},
								{ "sidebar-mobile-open": enableMobileMenu },
								{ "body-tabs-shadow-btn": enablePageTabsAlt }
							)}
						>
							<Header
								menuItems={menuItems.top}
								top={top}
								toggleSwitch={() =>
									this.setState({
										top: {
											...top,
											isToggleOn: !top.isToggleOn
										}
									})
								}
								onChangeTopTabs={this._onChangeTopTabs}
								showProductsDropdown={false}
								showDateSelector={false}
								disabled={!this.state.isPolygonSelected}
								handleMicroSurveyClick={this.handleMicroSurveyClick}
							/>
							<div className="app-main">
								<AppSideBar onChangeLeftMenu={this._onChangeLeftMenu} menuItems={menuItems.left} />
								<div className="app-main__outer">{this._toggleInnerViews()}</div>
							</div>
						</div>
						<ScrollUpButton />
					</Fragment>
				)}
			/>
		);
	}
}

const mapStateToProp = (state) => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
});

export default withRouter(connect(mapStateToProp)(CovidLayout));
