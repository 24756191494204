import React, { PureComponent } from "react";
import { GoogleApiWrapper, Map } from "google-maps-react";
import mapLegend from "../utils/mapLegendsData";
import { Col, Button, Spinner } from "reactstrap";
import { MdLocationOff, MdLocationOn, MdTimer, MdTimerOff, MdLayers, MdLayersClear } from "react-icons/md";
import Tooltip from "./tooltip";

const { MyStyle } = require("../../../assets/js/google-maps-styles.js");

class GoogleMap extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			bounds: {},
			toggle: true,
			showPatientMarkers: false,
			keepDateHistory: false,
			showDateHistoryButton: false,
			showPatientMarkerButton: false,
			polygons: [],
			markers: [],
			infoWindows: [],
			polylines: [],
			patientMarkers: [],
			circles: [],
			historyCircles: [],
			historyMarkers: [],
			// isMounted: true,
			isUpdating: false
		};
		this.containerStyle = {
			position: "inherit",
			width: "auto",
			height: "auto"
		};
		this.mapRef = React.createRef();
	}

	// componentWillMount() {
	// 	if (this.props.getRef) this.props.getRef(this.mapRef);
	// }

	componentDidMount() {
		if (this.props.getRef) this.props.getRef(this.mapRef);
		this.setBounds(this.props.center);
		// try {
		// 	const bounds = new this.props.google.maps.LatLngBounds();
		// 	bounds.extend(this.props.center);
		// 	this.setState({ bounds });
		// } catch (e) {}
	}

	componentDidUpdate(prevProps) {
		// if (prevProps.mapToggle !== this.props.mapToggle || this.props.mapToggle === undefined) {
		if (prevProps.mapToggle !== this.props.mapToggle && this.props.mapToggle === false) {
			this.setBounds();
		}
		if (this.props.keepDateHistory !== this.state.keepDateHistory) {
			this.setState({ keepDateHistory: this.props.keepDateHistory });
		}
		if ((this.props.markers && prevProps.markers && this.props.markers.length !== prevProps.markers.length) || (this.props.markers && !prevProps.markers)) {
			this.setBounds();
		}
		// if ((this.props.patientMarkers && prevProps.patientMarkers && this.props.patientMarkers.length !== prevProps.patientMarkers.length) || (this.props.patientMarkers && !prevProps.patientMarkers)) {
		// 	this.setBounds();
		// }
	}

	setBounds = (center) => {
		let { markers, patientMarkers, circles, historyCircles, historyMarkers, polylines } = this.props;
		let bounds = new this.props.google.maps.LatLngBounds();
		let change = false;
		try {
			if (center) {
				bounds.extend(center);
				bounds.extend ({lat: center.lat - 0.1, lng: center.lng - 0.1});
				bounds.extend ({lat: center.lat + 0.1, lng: center.lng + 0.1});
				change = true;
			} else {
				if (markers && markers.length > 0) {
					markers.forEach((marker) => {
						console.log (marker);
						if (marker && marker.props && marker.props.position) bounds.extend(marker.props.position);
					});
					change = true;
				}
				if (patientMarkers && patientMarkers.length > 0) {
					patientMarkers.forEach((marker) => {
						if (marker && marker.props && marker.props.position) bounds.extend(marker.props.position);
					});
					change = true;
				}
				if (historyCircles && historyCircles.length > 0) {
					historyCircles.forEach((circle) => {
						if (circle && circle.props && circle.props.center) bounds.extend(circle.props.center);
					});
					change = true;
				}
				if (historyMarkers && historyMarkers.length > 0) {
					historyMarkers.forEach((marker) => {
						if (marker && marker.props && marker.props.position) bounds.extend(marker.props.position);
					});
					change = true;
				}
				if (circles && circles.length > 0) {
					circles.forEach((circle) => {
						if (circle && circle.props && circle.props.center) bounds.extend(circle.props.center);
					});
					change = true;
				}
				if (polylines && polylines.length > 0) {
					polylines.forEach((polyline) => {
						if (polyline && polyline.props && polyline.props.path && polyline.props.path.length > 0)
							polyline.props.path.forEach((coordinates) => {
								if (coordinates) bounds.extend(coordinates);
							});
					});
					change = true;
				}
			}
			if (change) this.setState({ bounds });
		} catch (e) {
			console.error (e);
		}
	};

	renderMapLegends = () => {
		if (this.props.selectedMenu === "population") {
			return (
				<Col
					style={{
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					{/* <h6 className="text-center mb-2 text-dark">Population Legend</h6> */}
					{/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}> */}
					{mapLegend.population.map((item, index) => (
						<span className={"mapLegends"} style={{ color: item.color }}>
							<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
							{item.population}
						</span>
					))}
					{/* </div> */}
				</Col>
			);
		} else if (this.props.selectedMenu === "seclevel") {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					{/* <h6 className="text-center mb-2 text-dark">SEC Level Legend</h6> */}
					<div className="mapLegendsWrapper">
						{mapLegend.sec.map((item, index) => (
							<span className={"mapLegends"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</div>
				</Col>
			);
		} else if (this.props.legend && this.props.legend.length > 0 && (this.props.selectedTab === 0 || this.props.selectedTab === 2)) {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					{/* <h6 className="text-center mb-2 text-dark">Market Share Legend</h6> */}
					<div className="mapLegendsWrapper">
						{this.props.legend.map((item, index) => (
							<span className={"mapLegends"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</div>
				</Col>
			);
		} else if (this.props.legend && this.props.legend.length > 0 && this.props.selectedTab === 1) {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					{/* <h6 className="text-center mb-2 text-dark">Revenue Potential Legend</h6> */}
					<div className="mapLegendsWrapper">
						{this.props.legend.map((item, index) => (
							<span className={"mapLegends"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</div>
				</Col>
			);
		}
	};

	togglePatientMarkers = () => {
		this.setState({ showPatientMarkers: !this.state.showPatientMarkers });
	};

	toggleKeepDateHistory = () => {
		this.setState({ keepDateHistory: !this.state.keepDateHistory }, () => (this.props.toggleKeepDateHistory ? this.props.toggleKeepDateHistory() : null));
	};

	render() {
		const {
			zoom,
			center,
			loading,
			onBoundsChanged,
			rangeSlider,
			showDateHistoryButton,
			showPatientMarkerButton,
			markers,
			patientMarkers,
			circles,
			historyCircles,
			historyMarkers,
			infoWindows,
			polylines,
			mapToggle,
      loadingPois,
      showOverlapping,
      toggleShowOverlapping
		} = this.props;
		const { bounds, keepDateHistory, showPatientMarkers } = this.state;
		return (
			<div className="container p-0">
				<div className="rangeSlider row mx-0">
					{/* {this.renderMapLegends()} */}
					{rangeSlider}
				</div>
				{loading ? (
					<div
						className="row align-items-center"
						style={{
							zIndex: 1000,
							alignSelf: "center",
							position: "absolute",
							// top: 150,
							// left: 200,
							backgroundColor: "#000000c0",
							width: "100%",
							height: "100%"
						}}
					>
						<div className="col-2" />
						<div className="col-8 align-self-center">
							<p className="text-white text-center" style={{ fontSize: 20 }}>
								Loading data. Once data is loaded you can zoom into the selected polygon to see 100 Meter boxes
							</p>
						</div>
						<div className="col-2" />
						{/* <Loader loading={loading} size={"xl"} /> */}
					</div>
				) : (
					""
				)}
				{!mapToggle && (
					<Map
						ref={this.mapRef}
						containerStyle={this.containerStyle}
						styles={MyStyle[0]}
						google={this.props.google}
						zoom={zoom}
						initialCenter={{ ...center }}
						onReady={(map) => this.setBounds()}
						bounds={bounds}
						mapTypeControl={false}
						fullscreenControl={false}
						streetViewControl={false}
						onIdle={onBoundsChanged}
					>
						{[...markers]}
						{infoWindows}
						{polylines}
						{!keepDateHistory && circles && circles.length > 0 && [...circles]}
						{/* {!keepDateHistory && showPatientMarkers && patientMarkers && patientMarkers.length > 0 && [...patientMarkers]} */}
						{/* {keepDateHistory && historyCircles && historyCircles.length > 0 && [...historyCircles]} */}
						{/* {keepDateHistory && showPatientMarkers && historyMarkers && historyMarkers.length > 0 && [...historyMarkers]} */}
						{!keepDateHistory && showPatientMarkers && patientMarkers && [...patientMarkers]}
						{keepDateHistory && historyCircles && [...historyCircles]}
						{keepDateHistory && showPatientMarkers && historyMarkers && [...historyMarkers]}
					</Map>
				)}
				{showPatientMarkerButton && (
					<Button
						id="PatientMarkerToggle"
						className="btn btn-primary"
						style={{ position: "absolute", bottom: 30, left: 10 }}
						onClick={showPatientMarkerButton ? this.togglePatientMarkers : null}
						disabled={loadingPois}
					>
						{loadingPois && <Spinner size="sm" />}
						{!loadingPois && (patientMarkers && patientMarkers.length > 0 && showPatientMarkers ? <MdLocationOff /> : <MdLocationOn />)}
						<Tooltip text={`${showPatientMarkers ? "Disable" : "Enable"} Patient Markers`} placement="right-end" target="PatientMarkerToggle" />
					</Button>
				)}
				{showDateHistoryButton && (
					<Button
						id="CircleHistoryToggle"
						className="btn btn-primary"
						style={{ position: "absolute", bottom: 30, left: 60 }}
						onClick={showDateHistoryButton ? () => this.toggleKeepDateHistory() : null}
					>
						{/* <i className="fas fa-undo"></i> */}
						{keepDateHistory ? <MdTimerOff /> : <MdTimer />}
						{keepDateHistory && <Tooltip text="Disable 3 Day Circles" placement="right-end" target="CircleHistoryToggle" />}
						{!keepDateHistory && <Tooltip text="Enable 3 Day Circles" placement="right-end" target="CircleHistoryToggle" />}
					</Button>
				)}
				{showDateHistoryButton && (
					<Button
						id="OverlappingCirclesToggle"
						className="btn btn-primary p-1"
						style={{ position: "absolute", bottom: 30, left: 110 }}
						onClick={toggleShowOverlapping}
					>
						{showOverlapping ? <MdLayersClear style={{ width: 30, height: 23 }} /> : <MdLayers style={{ width: 30, height: 23 }} />}
						<Tooltip text={`Show ${ showOverlapping ? "All" : "Overlapping" } Circles`} placement="right-end" target="OverlappingCirclesToggle" />
					</Button>
				)}
			</div>
		);
	}
}

GoogleMap.defaultProps = {
	zoom: 10,
	center: { lat: 33.657645, lng: 73.071096 },
	markers: [],
	polygons: [],
	circles: [],
	polylines: [],
	onBoundsChanged: () => {}
};

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(GoogleMap);
