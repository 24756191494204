//no of patients, intensity , radius

import React, { Component, Fragment } from "react";
import { Container, Card, Row, Col } from "reactstrap";
// import UsersTable from "./users_table";
// import Map from "./Monitoring/map";
import Map from "./Analysis/map";
// import Graphs from "./graphs";
import ScrollUpButton from "react-scroll-up-button";
// import Timeline from "../../../components/Timeline";
// import { Submissions } from "../utils/timeline_data";
// import mapLegend from "../utils/mapLegendsData";
import ListView from "../../../components/ListView";
import { TimelineTable } from "../../../components/Table/TimelineTable";
import MonitorModal from "../../../components/Modal/MonitorModal";
// import { RangeSlider } from "../../../components/Sliders/RangeSlider";
import { CircleService, PoiService, PolygonService, PatientService } from "../../../services";
import { polygon, point, booleanPointInPolygon } from "@turf/turf";
import LoaderSVG from "../../../assets/images/loader.svg";

const tableColumns = [
	{ name: "ID" },
	{ name: "Form Thumbnail" },
	{ name: "Circle Name/Address" },
	{ name: "Radius" },
	{ name: "Enumerator Name" },
	{ name: "Alert Type" },
	{ name: "Compliance" }
];

const tableData = [
	{
		id: 1,
		thumbnail: require("../../../assets/images/medical.jpg"),
		circleName: "11,Kaghan Road,Sector F-8/3,Near Faisal Avenue, Islamabad",
		radius: "100m",
		enumName: "Sohail Niaz",
		alertType: "Red",
		compliance: "Market closed",
		class: "badge badge-danger"
	},
	{
		id: 2,
		thumbnail: require("../../../assets/images/medical.jpg"),
		circleName: "H.No.10,Street-30,F-7/1, Islamabad",
		radius: "100m",
		enumName: "Umair Iqbal",
		alertType: "Green",
		compliance: "Test Conducted",
		class: "badge badge-success"
	},
	{
		id: 3,
		thumbnail: require("../../../assets/images/medical.jpg"),
		circleName: "22-A,College Road,F-7/2,Opp.Jinnah Super Market, Islamabad",
		radius: "100m",
		enumName: "Kashif Saleem",
		alertType: "Red",
		compliance: "Market closed",
		class: "badge badge-danger"
	},
	{
		id: 4,
		thumbnail: require("../../../assets/images/medical.jpg"),
		circleName: "House NO.140,Street 14,E-7, Islamabad",
		radius: "100m",
		enumName: "Ransom Salute",
		alertType: "Red",
		compliance: "Market closed",
		class: "badge badge-danger"
	}
];

export default class Monitor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			selectedVoronoi: null,
			selectedBox: null,
			legends: null,
			modalVisible: false,
			circleData: null,
			dateList: [],
			clusterData: null,
			selectedDate: "",
			selectedCircleId: "",
			unionCouncils: [],
			loadingPois: false
		};
		this.weeksToDisplay = 2;
		this.mapRef = React.createRef();
		this.mapSearchDelay = 1000;
		this.playDelay = 2000;
		this.TAG = "ANALYSIS";
	}

	componentDidMount() {
		this.fetchCircles();
	}

	fetchCircles = async (_) => {
		try {
			if (this.state.clusterData) return;
			const { dateList, clusterData } = await CircleService.getCircles({ week: this.weeksToDisplay });
			// if (dateList.length > 0) this.setState({ dateList, clusterData, selectedDate: dateList[dateList.length - 1], loaded: true, loadingPois: true }, this.fetchCirclePois);
			if (dateList.length > 0) this.setState({ dateList, clusterData, selectedDate: dateList[dateList.length - 1], loaded: true, loadingPois: true });
			else this.setState({ loaded: true });
		} catch (error) {}
	};

	/*
	fetchCirclePatients = async () => {
		try {
			let { clusterData, dateList } = this.state;
			let patients = {};
			let patientPromises = dateList.map(async (date) => {
				const circles = clusterData[date];
				const circleIds = Object.keys(circles);
				patients[date] = await (await PatientService.getCirclePatients(circleIds)).data.data;
			});
			await Promise.all(patientPromises);
			for (const date of dateList) {
				for (const patient of patients[date]) {
					if (!clusterData[date][patient.circle_id].points) clusterData[date][patient.circle_id].points = [];
					clusterData[date][patient.circle_id].points = [
						...clusterData[date][patient.circle_id].points,
						{ lat: patient.location.coordinates[1], lng: patient.location.coordinates[0] }
					];
				}
			}
			this.setState({ clusterData, loadingPois: false });
		} catch (err) {
			console.error(err);
		}
	};
	// */

	fetchCirclePois = async (_) => {
		try {
			let { clusterData, dateList } = this.state;
			let pois = {};
			let poiPromises = dateList.map(async (date) => {
				const circles = clusterData[date];
				const circleIds = Object.keys(circles);
				const {
					data: { data }
				} = await PoiService.getCirclePois(circleIds, "case");
				pois[date] = data;
			});
			await Promise.all(poiPromises);
			dateList.forEach((date) => {
				let circles = { ...clusterData[date] };
				pois[date].forEach((poi) => {
					let circle = { ...circles[poi.circle_id] };
					circle.points = poi.circlepois.map((dataPoint) => {
						const coordinates = dataPoint.pinpoint.coordinates;
						return { lat: coordinates[1], lng: coordinates[0] };
					});
					circles[poi.circle_id] = circle;
				});
				clusterData[date] = circles;
			});
			this.setState({ clusterData, loadingPois: false }, this.fetchPolygons);
		} catch (error) {}
	};

	fetchPolygons = async (_) => {
		try {
			const {
				data: { data }
			} = await PolygonService.getPolygons(localStorage.getItem("currentUser"));
			const polygons = data.map((council) => {
				let { title: name, coordinates } = council;
				coordinates = [coordinates.map((coor) => [coor.lng, coor.lat])];
				return polygon(coordinates, { name });
			});
			this.setState({ unionCouncils: polygons }, this.tagCircles);
		} catch (error) {}
	};

	toggleModal = () => {
		this.setState({ modalVisible: !this.state.modalVisible });
	};

	handleSelectVoronoi = (voronoiId) => {
		if (voronoiId !== this.state.selectedVoronoi) this.setState({ selectedVoronoi: voronoiId, selectedBox: null });
		this.props.handleSelectedPolygon("voronoi", voronoiId);
	};

	handleSelectBox = (boxId) => {
		if (boxId !== this.state.selectedBox) this.setState({ selectedBox: boxId });
		this.props.handleSelectedPolygon("box", boxId);
	};

	handleSliderDataChange = (data) => {
		this.setState({ circleData: data });
	};

	handleOnSlide = (value) => {
		let { dateList } = this.state;
		if (dateList[value]) {
			// this.setState({ selectedDate: dateList[value] }, () => this.getPlaces());
			this.setState({ selectedDate: dateList[value] });
		}
	};

	getRef = (ref) => {
		this.mapRef = ref;
	};

	handleMapCircleClick = (date, id, pValue = 0) => {
		/*
		let { selectedDate, clusterData, selectedCircleId } = this.state;
		selectedCircleId = id;
		selectedDate = date;
		if (!clusterData[selectedDate][selectedCircleId].schools) clusterData[selectedDate][selectedCircleId].schools = [];
		if (!clusterData[selectedDate][selectedCircleId].markets) clusterData[selectedDate][selectedCircleId].markets = [];
		if (!clusterData[selectedDate][selectedCircleId].mosques) clusterData[selectedDate][selectedCircleId].mosques = [];
		if (!clusterData[selectedDate][selectedCircleId].stations) clusterData[selectedDate][selectedCircleId].stations = [];
		if (!clusterData[selectedDate][selectedCircleId].hospitals) clusterData[selectedDate][selectedCircleId].hospitals = [];
		this.setState({ clusterData, selectedCircleId, selectedDate }, () => {
			this.getPlaces();
			this.toggleModal();
		});
		// */

		let { selectedDate, selectedCircleId } = this.state;
		selectedCircleId = id;
		selectedDate = date;
		this.setState({ selectedCircleId, selectedDate }, () => {
			this.toggleModal();
		});
	};

	render() {
		let { dateList, clusterData, selectedDate, selectedCircleId, loadingPois, loaded } = this.state;
		if (loaded) {
			let selectedClusterData = {};
			if (clusterData) selectedClusterData = clusterData[selectedDate];
			return (
				<Fragment>
					<Container fluid>
						<Row className="height76" style={{ marginTop: 20 }}>
							<Col sm="12" lg="8" className="mb-3 mb-lg-0">
								<Card className="height76">
									{/* <Map
                  selectedTypes={selectedTypes}
                  selectedSegments={selectedSegments}
                  handleSelectVoronoi={this.handleSelectVoronoi}
                  handleSelectBox={this.handleSelectBox}
                  selectedVoronoi={selectedVoronoi}
                  selectedMenu={selectedMenu}
                  selectedTab={this.props.selectedTab}
                  selectedBox={selectedBox}
                /> */}
									<Map
										toggleModal={this.toggleModal}
										// dots={true}
										// handleSliderDataChange={this.handleSliderDataChange}
										dateList={dateList}
										selectedClusterData={selectedClusterData}
										selectedDate={selectedDate}
										loadingPois={loadingPois}
										handleOnSlide={this.handleOnSlide}
										getRef={this.getRef}
										handleMapCircleClick={this.handleMapCircleClick}
									/>
								</Card>
							</Col>
							<Col sm="12" lg="4" className="mb-3 mb-lg-0">
								<Card className="h100" style={{ alignContent: "center" }}>
									<ListView data={clusterData && clusterData[selectedDate] ? clusterData[selectedDate] : {}} />
								</Card>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col md="12">
								<TimelineTable tableColumns={tableColumns} data={tableData} title="Alert Monitoring Data" />
							</Col>
						</Row>
					</Container>
					<MonitorModal
						visible={this.state.modalVisible}
						toggleModal={this.toggleModal}
						data={clusterData && clusterData[selectedDate] && clusterData[selectedDate][selectedCircleId]}
					/>
					<ScrollUpButton />
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Row style={{ alignSelf: "center", justifyContent: "center" }}>
						<img src={LoaderSVG} className="loadersvg" img="source" alt="loader" />
					</Row>
				</Fragment>
			);
		}
	}
}
