import React from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};

const buildSlider = ({
    min,
    max,
    values,
    onSlide,
    count,
    // dots,
    // selectedTab,
    // step,
}) => {
    let marks = {};
    let delta = (max - min + 1) / count;
    for (let i = 0; i < count; i++) {
        let value = min + delta * i;
        marks[value] = {
            style: {
                color: "black",
            },
            label: (
                <strong>
                    {Math.round((value + Number.EPSILON) * 100) / 100}
                </strong>
            ),
        };
    }
    marks[max] = {
        style: {
            color: "black",
        },
        label: <strong>{max}</strong>,
    };
};

export const BasicSlider = ({
    min,
    max,
    values,
    onSlide,
    count,
    defaultValue,
}) => {
    return (
        <Slider
            min={min}
            max={max}
            defaultValue={defaultValue}
            className="rc-slider-primary mb-2"
            onChange={(value) => onSlide(value)}
            // handle={handle}
            // tipFormatter={(value) => `${values[value - 1]}`}
        />
    );
};
