import axios from "axios";
import Auth from "./auth";
const URL = process.env.REACT_APP_BACKEND_API_HOST + "/api/v1/circles";

class CircleService {
	static getCircles({ week, creationDate }) {
		try {
			let mUrl = URL + "/circle-list";
			let body = {
				week,
				circle_creation_date: creationDate,
				portal_user: localStorage.getItem("currentUser") ? localStorage.getItem("currentUser") : null
			};
			let options = {
				headers: {
					Authorization: `${Auth.getAuthorizationToken()}`
				}
			};
			return axios
				.post(mUrl, body, options)
				.then((response) => {
					let clusterData = {};
					let dateList = [];
					if (response.data && response.data.listings && response.data.listings.length > 0) {
						response.data.listings.forEach((item) => {
							if (!clusterData[item.circle_creation_date]) {
								clusterData[item.circle_creation_date] = {};
								dateList.push(item.circle_creation_date);
							}
							clusterData[item.circle_creation_date][item._id] = {};
							clusterData[item.circle_creation_date][item._id].id = item._id;
							clusterData[item.circle_creation_date][item._id].address = item.address;
							clusterData[item.circle_creation_date][item._id].alerts = [{ alert_type: item.alert_type }];
							clusterData[item.circle_creation_date][item._id].cases = item.total_cases;
							clusterData[item.circle_creation_date][item._id].date = item.circle_creation_date;
							clusterData[item.circle_creation_date][item._id].infected = item.infected;
							clusterData[item.circle_creation_date][item._id].removed = item.removed;
							if (item.circlepois) {
								clusterData[item.circle_creation_date][item._id].points = item.circlepois.map((poi) => {
									return { lat: poi.pinpoint.coordinates[1], lng: poi.pinpoint.coordinates[0] };
								});
							}
							clusterData[item.circle_creation_date][item._id].lat = item.location.coordinates[1];
							clusterData[item.circle_creation_date][item._id].lng = item.location.coordinates[0];
							clusterData[item.circle_creation_date][item._id].pValue = item.p_value;
							clusterData[item.circle_creation_date][item._id].ir = item.ir;
							clusterData[item.circle_creation_date][item._id].population = item.population;
							clusterData[item.circle_creation_date][item._id].radius = item.radius;
							clusterData[item.circle_creation_date][item._id].user_id = item.user_id;
							clusterData[item.circle_creation_date][item._id].ucName = item.union_council_id.name;

							clusterData[item.circle_creation_date][item._id].points = item.patient_id.map((patient) => {
								return { lat: patient.location.coordinates[1], lng: patient.location.coordinates[0] };
							});

							clusterData[item.circle_creation_date][item._id].hospitals = [];
							clusterData[item.circle_creation_date][item._id].markets = [];
							clusterData[item.circle_creation_date][item._id].mosques = [];
							clusterData[item.circle_creation_date][item._id].schools = [];
							clusterData[item.circle_creation_date][item._id].stations = [];
						});
						return { clusterData, dateList };
					} else return { clusterData: {}, dateList: [] };
				})
				.catch((error) => Promise.reject(error));
		} catch (e) {
			throw e;
		}
	}

	static getCircleDataByCircleId(circle_id) {
		try {
			if (!circle_id || circle_id === "") throw new Error("Circle ID required");
			let mUrl = `${URL}/${circle_id}`;
			let options = {
				headers: {
					Authorization: `${Auth.getAuthorizationToken()}`
				}
			};
			return axios
				.get(mUrl, options)
				.then((response) => {
					let { bus_station, church, hindu_temple, hospital, mosque, school, market } = response.data.data;
					let mosques = [];
					if (church && church.length) mosques = [...mosques, ...church];
					if (hindu_temple && hindu_temple.length) mosques = [...mosques, ...hindu_temple];
					if (mosque && mosque.length) mosques = [...mosques, ...mosque];
					let clusterData = {
						schools: school && school.length ? [...school] : [],
						markets: market && market.length ? [...market] : [],
						mosques: mosques,
						stations: bus_station && bus_station.length ? [...bus_station] : [],
						hospitals: hospital && hospital.length ? [...hospital] : []
					};
					for (let key in clusterData) {
						clusterData[key].forEach((item) => {
							try {
								item.lat = item.pinpoint.coordinates[1];
								item.lng = item.pinpoint.coordinates[0];
							} catch (e) {}
						});
					}
					return clusterData;
				})
				.catch((e) => {
					throw e;
				});
		} catch (e) {
			throw e;
		}
	}
}

export default CircleService;
