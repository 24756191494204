const dataList1 = [
    "2020-03-01.json",
    "2020-03-02.json",
    "2020-03-03.json",
    "2020-03-04.json",
    "2020-03-05.json",
    "2020-03-06.json",
    "2020-03-07.json",
    "2020-03-08.json",
    "2020-03-09.json",
    "2020-03-10.json",
    "2020-03-11.json",
    "2020-03-12.json",
    "2020-03-13.json",
    "2020-03-14.json",
    "2020-03-15.json",
    "2020-03-16.json",
    "2020-03-17.json",
    "2020-03-18.json",
    "2020-03-19.json",
    "2020-03-20.json",
    "2020-03-21.json",
    "2020-03-22.json",
    "2020-03-23.json",
    "2020-03-24.json",
    "2020-03-25.json",
    "2020-03-26.json",
    "2020-03-27.json",
    "2020-03-28.json",
    "2020-03-29.json",
    "2020-03-30.json",
];

const dataList = [
    "2020-05-01.json",
    "2020-05-02.json",
    "2020-05-03.json",
    "2020-05-04.json",
    "2020-05-05.json",
    "2020-05-06.json",
    "2020-05-07.json",
    "2020-05-08.json",
    "2020-05-09.json",
    "2020-05-10.json",
    "2020-05-11.json",
    "2020-05-12.json",
    "2020-05-13.json",
    "2020-05-14.json",
    "2020-05-15.json",
    "2020-05-16.json",
    "2020-05-17.json",
    "2020-05-18.json",
    "2020-05-19.json",
    "2020-05-20.json",
    "2020-05-21.json",
];

const getDataList = () => {
    return dataList;
};

module.exports = {
    getDataList,
};
