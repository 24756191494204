const axios = require("axios");

const searchToEndpoint = {
	detail: "detail",
	search: "search",
	reverse: "reverse"
};

const baseUrl = "https://nominatim.openstreetmap.org/";
const format = "json";
let limit = 50;

const searchNearby = (position, keyword, radius) => {
	try {
		let query = `${baseUrl}search?q=${keyword}%20near%20[${position.lat},${position.lng}]&format=${format}&limit=${limit}`;
		return axios
			.get(query)
			.then((response) => {
				let result = [];
				response.data.forEach((data) => {
					let distance = calculateDistance(position, { lat: data.lat, lng: data.lon });
					if (distance <= radius / 1000) {
						result.push({
							distance: calculateDistance(position, { lat: data.lat, lng: data.lon }),
							lat: data.lat,
							lng: data.lon,
							name: data.display_name,
							osmId: data.osm_id,
							placeId: data.place_id,
							type: data.type
						});
					}
				});
				return sortByDistance(result);
			})
			.catch((e) => {
				throw e;
			});
	} catch (e) {
		throw e;
	}
};

const calculateDistance = (loc1, loc2) => {
	// const R = 6371e3; // metres
	const R = 6371; // kilometres
	const φ1 = (loc1.lat * Math.PI) / 180; // φ, λ in radians
	const φ2 = (loc2.lat * Math.PI) / 180;
	const Δφ = ((loc2.lat - loc1.lat) * Math.PI) / 180;
	const Δλ = ((loc2.lng - loc1.lng) * Math.PI) / 180;

	const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const d = R * c; // in kilometres
	return Math.round((d + Number.EPSILON) * 100) / 100;
};

const sortByDistance = (locationsWithDistance) => {
	return locationsWithDistance.sort((a, b) => (a.distance >= b.distance ? 1 : -1));
};

export default { searchNearby };
