import React, { Component, Fragment } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import Map from "./map";
import ScrollUpButton from "react-scroll-up-button";
import ListView from "../../../../components/ListView/index";
import StatsModal from "./StatsModal";
import { AnalysisTable } from "../../../../components/Table/AnalysisTable";
// import Clusters from "../../data/clusters.json";
import { osmService, googleService, AlertService, CircleService, PoiService, PolygonService, PatientService } from "../../../../services";
import LoaderSVG from "../../../../assets/images/loader.svg";
import { polygon, point, booleanPointInPolygon, distance } from "@turf/turf";
import { filterOverlappingRedAlerts, getClusterUC } from '../../utils/clustersData';
const tableColumns = [{ name: "ID" }, { name: "Surveyor Name" }, { name: "Patient Name" }, { name: "Gender" }, { name: "Age" }, { name: "Address" }, { name: "Severity" }];

export default class Analysis extends Component {
	constructor(props) {
		super(props);
		// let allFiles = Object.keys(Clusters);
		// let dateList = allFiles.map((file) => {
		// 	return file.split(".")[0];
		// });
		this.state = {
			// modalData: null,

			// dateList: [...dateList],
			// clusterData: { ...Clusters },
			// selectedDate: dateList[dateList.length - 1],
			// loaded: false

			dateList: [],
			clusterData: null,
			selectedDate: "",
			loaded: false,

			modalVisible: false,
			circleData: null,
			fetchingPlaces: false,
			selectedCircleId: "",
			dateHistory: [],
			keepDateHistory: false,
			isPlaying: false,
			loadingPois: false,
			unionCouncils: [],
			showOverlapping: false
		};
		this.weeksToDisplay = 2;
		this.mapRef = React.createRef();
		this.mapSearchDelay = 1000;
		this.playDelay = 2000;
		this.TAG = "ANALYSIS";
	}

	componentDidMount() {
		this.fetchCircles();
	}

	fetchCircles = async (_) => {
		try {
			if (this.state.clusterData) return;
			const { dateList, clusterData } = await CircleService.getCircles({ week: this.weeksToDisplay });
			// // if (dateList.length > 0) this.setState({ dateList, clusterData, selectedDate: dateList[dateList.length - 1], loaded: true, loadingPois: true }, this.fetchCirclePois);
			// if (dateList.length > 0) this.setState({ dateList, clusterData, selectedDate: dateList[dateList.length - 1], loaded: true, loadingPois: true }, this.fetchCirclePatients);

			if (dateList.length > 0) this.setState({ dateList, clusterData, selectedDate: dateList[dateList.length - 1], loaded: true, loadingPois: false });
			else this.setState({ loaded: true });
		} catch (error) {}
	};

	/*
	fetchCirclePatients = async () => {
		try {
			let { clusterData, dateList } = this.state;
			let patients = {};
			let patientPromises = dateList.map(async (date) => {
				const circles = clusterData[date];
				const circleIds = Object.keys(circles);
				patients[date] = await (await PatientService.getCirclePatients(circleIds)).data.data;
			});
			await Promise.all(patientPromises);
			for (const date of dateList) {
				for (const patient of patients[date]) {
					if (!clusterData[date][patient.circle_id].points) clusterData[date][patient.circle_id].points = [];
					clusterData[date][patient.circle_id].points = [
						...clusterData[date][patient.circle_id].points,
						{ lat: patient.location.coordinates[1], lng: patient.location.coordinates[0] }
					];
				}
			}
			this.setState({ clusterData, loadingPois: false });
		} catch (err) {
			console.error(err);
		}
	};
	// */

	fetchCirclePois = async (_) => {
		try {
			let { clusterData, dateList } = this.state;
			let pois = {};
			let poiPromises = dateList.map(async (date) => {
				const circles = clusterData[date];
				const circleIds = Object.keys(circles);
				const {
					data: { data }
				} = await PoiService.getCirclePois(circleIds, "case");
				pois[date] = data;
			});
			await Promise.all(poiPromises);
			dateList.forEach((date) => {
				let circles = { ...clusterData[date] };
				pois[date].forEach((poi) => {
					let circle = { ...circles[poi.circle_id] };
					circle.points = poi.circlepois.map((dataPoint) => {
						const coordinates = dataPoint.pinpoint.coordinates;
						return { lat: coordinates[1], lng: coordinates[0] };
					});
					circles[poi.circle_id] = circle;
				});
				clusterData[date] = circles;
			});
			this.setState({ clusterData, loadingPois: false }, this.tagUnionCouncils);
		} catch (error) {}
	};

	tagUnionCouncils = _ => {
		const { clusterData } = this.state;
		getClusterUC(clusterData, ucNames => {
			Object.keys(clusterData).forEach(date => {
				const circles = { ...clusterData[date] };
				Object.keys(circles).forEach(circleId => {
					let circle = { ...circles[circleId] };
					circle.ucName = ucNames[date][circleId];
					circles[circleId] = circle;
				});
				clusterData[date] = circles;
			});
			this.setState({ clusterData });
		});
	}

	toggleShowOverlapping = (_) => this.setState({ showOverlapping: !this.state.showOverlapping });

	toggleModal = (data) => {
		this.setState({
			modalVisible: !this.state.modalVisible
			// modalData: data
		});
	};

	// handleSliderDataChange = (data) => {
	// 	this.setState({ circleData: data, fetchingPlaces: true });
	// };

	// handlePlacesList = (schools, markets, mosques, stations) => {
	// 	let { circleData } = this.state;
	// 	let mData = circleData[Object.keys(circleData)[0]];
	// 	mData.schools = schools;
	// 	mData.markets = markets;
	// 	mData.mosques = mosques;
	// 	mData.stations = stations;

	// 	this.setState({ circleData, fetchingPlaces: false, modalData: { ...mData } });
	// };

	handleOnSlide = (value) => {
		let { dateList } = this.state;
		if (dateList[value]) {
			// this.setState({ selectedDate: dateList[value] }, () => this.getPlaces());
			this.setState({ selectedDate: dateList[value] }, () => this.toggleKeepDateHistory(!this.state.keepDateHistory));
		}
	};

	delayedPlaceSearch = (results, keywordList, ind, circleData, cb) => {
		let t = "delayedPlaceSearch";
		try {
			let position = { lat: circleData.lat, lng: circleData.lng };
			let element = this.mapRef.current;
			setTimeout(async () => {
				try {
					results[keywordList[ind]] = await googleService.searchNearby(element, position, keywordList[ind], circleData.radius);
					// results[keywordList[ind]] = [];
				} catch (e) {
					results[keywordList[ind]] = [];
					this.err(t, e);
				}
				ind++;
				if (ind < keywordList.length) this.delayedPlaceSearch(results, keywordList, ind, circleData, cb);
				else cb(results);
			}, this.mapSearchDelay);
		} catch (e) {
			this.err(t, e);
			cb(results);
		}
	};

	getPlaces = () => {
		let t = "getPlaces";
		this.setState({ fetchingPlaces: true }, () => {
			let { selectedDate, clusterData, selectedCircleId } = this.state;
			let circleData = clusterData[selectedDate][selectedCircleId];

			let position = { lat: circleData.lat, lng: circleData.lng };
			let results = {};
			let keywordList = ["school", "store", "mosque", "church", "hindu_temple", "bus_station", "hospital"];
			this.delayedPlaceSearch(results, keywordList, 0, circleData, async (results) => {
				let osmStations = [];
				try {
					osmStations = await osmService.searchNearby(position, "bus station", circleData.radius);
				} catch (e) {}
				let { school, store, mosque, church, hindu_temple, bus_station, hospital } = results;
				circleData.schools = school;
				circleData.markets = store;
				circleData.mosques = [...mosque, ...church, ...hindu_temple];
				circleData.stations = [...bus_station, ...osmStations];
				circleData.hospitals = hospital;
				this.setState({ clusterData, fetchingPlaces: false });
			});

			/*
			CircleService.getCircleDataByCircleId(circleData.id).then((output) => {
				let { schools, markets, mosques, stations, hospitals } = output;
				circleData.schools = schools;
				circleData.markets = markets;
				circleData.mosques = mosques;
				circleData.stations = stations;
				circleData.hospitals = hospitals;
				this.setState({ clusterData, fetchingPlaces: false });
			});
			*/
		});
	};

	handleMapCircleClick = (date, id, pValue = 0) => {
		/*
		let { selectedDate, clusterData, selectedCircleId } = this.state;
		selectedCircleId = id;
		selectedDate = date;
		if (!clusterData[selectedDate][selectedCircleId].schools) clusterData[selectedDate][selectedCircleId].schools = [];
		if (!clusterData[selectedDate][selectedCircleId].markets) clusterData[selectedDate][selectedCircleId].markets = [];
		if (!clusterData[selectedDate][selectedCircleId].mosques) clusterData[selectedDate][selectedCircleId].mosques = [];
		if (!clusterData[selectedDate][selectedCircleId].stations) clusterData[selectedDate][selectedCircleId].stations = [];
		if (!clusterData[selectedDate][selectedCircleId].hospitals) clusterData[selectedDate][selectedCircleId].hospitals = [];
		this.setState({ clusterData, selectedCircleId, selectedDate }, () => {
			this.getPlaces();
			this.toggleModal();
		});
		// */
		console.log(id);
		let { selectedDate, selectedCircleId } = this.state;
		selectedCircleId = id;
		selectedDate = date;
		this.setState({ selectedCircleId, selectedDate }, () => {
			this.getPlaces();
			this.toggleModal();
		});
	};

	toggleKeepDateHistory = (keepDateHistory = this.state.keepDateHistory) => {
		let { selectedDate, dateList } = this.state;
		let dateHistory = [];
		keepDateHistory = !keepDateHistory;
		if (keepDateHistory) {
			let startInd = dateList.indexOf(selectedDate);
			if (startInd !== -1) {
				// let endInd = startInd + 2 < dateList.length ? startInd + 2 : startInd + 1 < dateList.length ? startInd + 1 : startInd;
				let endInd = dateList.length - 1;
				for (let i = startInd; i <= endInd; i++) {
					dateHistory.push(dateList[i]);
				}
			}
		}
		this.setState({ keepDateHistory, dateHistory });
	};

	getRef = (ref) => {
		this.mapRef = ref;
	};

	logger() {
		let args = Array.from(arguments);
	}

	err() {
		let args = Array.from(arguments);
	}

	handleSendAlert = async (alertType, circleData) => {
		let t = "handleSendAlert";
		try {
			let payload = {
				// user_id: "asim.iqbal@surveyauto.com",
				circle: { ...circleData },
				alert: { alert_type: alertType, type: 1 }
			};
			let response = await AlertService.sendAlert(payload);
			if (response) return true;
			else return false;
		} catch (e) {
			this.err(t, e);
			return false;
		}
	};

	handlePlayButtonClick = () => {
		let isPlaying = !this.state.isPlaying;
		this.setState({ isPlaying }, () => this.cycleCircles(0));
	};

	cycleCircles = (ind) => {
		try {
			setTimeout(() => {
				if (this.state.isPlaying) {
					let { dateList, dateHistory, keepDateHistory } = this.state;
					if (ind === 0) dateHistory = [];
					keepDateHistory = true;
					dateHistory.push(dateList[ind]);
					let selectedDate = dateList[ind];
					this.setState({ dateHistory, selectedDate, keepDateHistory }, () => {
						ind++;
						if (ind < dateList.length) this.cycleCircles(ind);
						else {
							ind = 0;
							this.cycleCircles(ind);
						}
					});
				} else {
					this.setState({ dateHistory: [], keepDateHistory: false });
				}
			}, this.playDelay);
		} catch (e) {}
	};


	render() {
		let { dateList, clusterData, selectedDate, selectedCircleId, keepDateHistory, dateHistory, isPlaying, loaded, loadingPois, showOverlapping } = this.state;
		if (loaded) {
			let selectedClusterData = {};
			if (dateList.length > 0) {
				let colors = [
					// "#D92550",
					// "#F7B924",
					// "#3AC47D",
					// "#545CD8",
					"#fcef9b",
					"#6e79a7",
					"#e5af6b",
					"#eb3def",
					"#9a013d",
					"#ff7b2e",
					"#1c8cfe",
					"#1dfd4c",
					"#40bbb2",
					"#1b4444",
					"#a9d452",
					"#bd4c63",
					"#c79037",
					"#8e3f0c"
				];
				if (!keepDateHistory) {
					selectedClusterData = clusterData[selectedDate];
					if (showOverlapping) selectedClusterData = filterOverlappingRedAlerts({ "dummyDate": selectedClusterData })["dummyDate"]
				}
				else {
					dateHistory.forEach((date, ind) => {
						selectedClusterData[date] = clusterData[date];
						// selectedClusterData[date].color = colors[ind % colors.length];
					});
					if (showOverlapping) selectedClusterData = filterOverlappingRedAlerts(selectedClusterData);
				}
			}
			return (
				<Fragment>
					<Container fluid>
						<Row className="height76" style={{ marginTop: 20 }}>
							<Col sm="12" lg="8" className="mb-3 mb-lg-0">
								<Card className="height76">
									<Map
										toggleModal={this.toggleModal}
										// handleSliderDataChange={this.handleSliderDataChange}
										// handlePlacesList={this.handlePlacesList}
										dateList={dateList}
										selectedClusterData={selectedClusterData}
										handleOnSlide={this.handleOnSlide}
										selectedDate={selectedDate}
										handleMapCircleClick={this.handleMapCircleClick}
										showDateHistoryButton={true}
										showPatientMarkerButton={true}
										showPlayButton={true}
										keepDateHistory={keepDateHistory}
										toggleKeepDateHistory={this.toggleKeepDateHistory}
										getRef={this.getRef}
										handlePlayButtonClick={this.handlePlayButtonClick}
										isPlaying={isPlaying}
										loadingPois={loadingPois}
										showOverlapping={showOverlapping}
										toggleShowOverlapping={this.toggleShowOverlapping}
									/>
								</Card>
							</Col>
							<Col sm="12" lg="4" className="mb-3 mb-lg-0">
								<Card className="h100" style={{ alignContent: "center" }}>
									{/* <ListView data={this.state.circleData} /> */}
									<ListView data={clusterData && clusterData[selectedDate] ? clusterData[selectedDate] : {}} />
								</Card>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col md="12">
								<AnalysisTable tableColumns={tableColumns} data={clusterData && clusterData[selectedDate] ? clusterData[selectedDate] : {}} title="Live Analysis Feed" />
							</Col>
						</Row>
						{/* <StatsModal visible={this.state.modalVisible} toggleModal={this.toggleModal} title="Islamabad" data={this.state.modalData} fetchingPlaces={this.state.fetchingPlaces} /> */}
						<StatsModal
							visible={this.state.modalVisible}
							toggleModal={this.toggleModal}
							title="Islamabad"
							data={clusterData && clusterData[selectedDate] && clusterData[selectedDate][selectedCircleId] ? clusterData[selectedDate][selectedCircleId] : {}}
							fetchingPlaces={this.state.fetchingPlaces}
							selectedDate={selectedDate}
							handleSendAlert={this.handleSendAlert}
						/>
					</Container>
					<ScrollUpButton />
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					{/* <Card> */}
					{/* <CardBody> */}
					{/* <img src={LoaderSVG} className="loadersvg" img="source" style={{ alignSelf: "center", marginLeft: "40%", marginTop: "20%" }} alt="loader" /> */}
					<Row style={{ alignSelf: "center", justifyContent: "center" }}>
						<img src={LoaderSVG} className="loadersvg" img="source" alt="loader" />
					</Row>
					{/* </CardBody> */}
					{/* </Card> */}
				</Fragment>
			);
		}
	}
}
