import React, { Component } from "react";
import GoogleMap from "../../common/google_map";
// import {
// 	createMarkersFromType,
// 	createMarkersFromSegment,
// 	createVoronois,
// 	createBoxes,
// 	createBoxMarkers,
// 	createPopulationVoronois,
// 	createSecPolygons,
// 	createVoronoiInfoWindow,
// 	updateVoronoiColors,
// 	createSelectedBox,
// 	createBoxesInBoundary
// } from "../../utils/map_data";
import { BasicSlider } from "../../../../components/Sliders/BasicSlider";
import { Marker, Circle } from "google-maps-react";
import BlueIcon from "../../../../assets/images/blue-dot-pastel-8.png";
// import Clusters from "../../data/clusters.json";

// import { osmService } from "../../../../services";
import { Row, Col } from "reactstrap";
const pValueThreshold = 0.05;

class Map extends Component {
	constructor(props) {
		// let allFiles = Object.keys(Clusters);
		// let values = allFiles.map((file) => {
		// 	return file.split(".")[0];
		// });
		super(props);
		this.state = {
			zoom: 11,
			// slider: {
			// 	active: true,
			// 	min: 0,
			// 	max: values.length - 1,
			// 	defaultValue: values.length - 1,
			// 	count: values.length,
			// 	step: 1,
			// 	values: values,
			// 	value: values.length - 1
			// },
			slider: {
				active: true,
				min: 0,
				max: 1,
				defaultValue: 0,
				count: 0,
				step: 1,
				values: [],
				value: 0
			},
			map: null,
			circles: [],
			markers: [],
			dateList: [],
			selectedClusterData: {},
			historyCircles: [],
			historyMarkers: [],
			mapToggle: false
		};
	}

	componentDidMount() {
		try {
			try {
				let { selectedClusterData, dateList } = this.props;
				if (selectedClusterData) {
					let { slider } = this.state;
					if (dateList && dateList.length > 0) {
						slider.active = true;
						slider.min = 0;
						slider.max = dateList.length - 1;
						slider.defaultValue = dateList.length - 1;
						slider.count = dateList.length;
						slider.values = dateList;
						slider.value = dateList.length - 1;
						let { circles, markers } = this.createCirclesFromData(selectedClusterData);
						this.setState({ selectedClusterData, dateList, slider, circles: [], markers: [] }, () => {
							this.setState({ circles, markers });
						});
					}
				}
				// else this.handleOnSlide(this.state.slider.defaultValue);
			} catch (e) {}
		} catch (e) {}
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		try {
			let { selectedClusterData, keepDateHistory, selectedDate, isPlaying } = this.props;
			if (selectedClusterData) {
				if (prevProps.selectedClusterData !== selectedClusterData) {
					if (!keepDateHistory) {
						let { circles, markers } = this.createCirclesFromData(selectedClusterData);
						this.setState({ selectedClusterData, circles: [], markers: [], historyCircles: [], historyMarkers: [], mapToggle: !isPlaying && true }, () => {
							// this.setState({ selectedClusterData, circles: [], markers: [], historyCircles: [], historyMarkers: [], mapToggle: true }, () => {
							this.setState({ circles, markers, mapToggle: false });
						});
					} else {
						let { circles, markers } = this.createCirclesFromDataForMultipleDates(selectedClusterData);
						this.setState({ selectedClusterData, circles: [], markers: [], historyCircles: [], historyMarkers: [], mapToggle: !isPlaying && true }, () => {
							// this.setState({ selectedClusterData, circles: [], markers: [], historyCircles: [], historyMarkers: [], mapToggle: true }, () => {
							this.setState({ historyCircles: circles, historyMarkers: markers, mapToggle: false });
						});
					}
				}
			}
			if (isPlaying) {
				this.changeSlider(selectedDate);
			}
		} catch (e) {}
	}

	onBoundsChanged = (_, map = this.state.map) => {
		const newZoom = map.getZoom();
		const NE = map.getBounds().getNorthEast();
		const SW = map.getBounds().getSouthWest();
		const { zoom } = this.state;
		const updates = {};
		updates.zoom = newZoom;
		updates.map = map;
		this.setState({ ...updates });
	};

	handleOnSlide = async (value) => {
		try {
			if (this.props.handleOnSlide) {
				let { slider } = this.state;
				slider.value = value;
				this.props.handleOnSlide(value);
				this.setState({ slider });
				return;
			}
		} catch (e) {}
	};

	changeSlider = (selectedDate) => {
		if (selectedDate) {
			let { slider } = this.state;
			slider.value = slider.values.indexOf(selectedDate);
			this.setState({ slider });
		}
	};

	_resetSelect = () => {
		try {
		} catch (e) {}
	};

	createCirclesFromData = (dataObject) => {
		try {
			let circles = [];
			let markers = [];
			let keys = Object.keys(dataObject);
			for (const key of keys) {
				if (key !== "color") {
					let data = dataObject[key];
					if (data.points && data.points.length > 0) markers = [...markers, ...this.createMarkersFromData(data.points, data.id)];
					circles.push(
						<Circle
							key={`circle_${data.id}`}
							radius={this.props.dots ? data.radius / 6 : data.radius}
							center={{
								lat: parseFloat(data.lat),
								lng: parseFloat(data.lng)
							}}
							strokeColor="black"
							strokeOpacity={1}
							strokeWeight={3}
							fillColor={this.getCircleColorBasedOnAlert(data)}
							fillOpacity={0.7}
							onClick={this.props.handleMapCircleClick ? () => this.props.handleMapCircleClick(data.date, data.id, data.pValue) : () => this.props.toggleModal(data)}
						/>
					);
				}
			}
			return { circles, markers };
		} catch (e) {}
	};

	getCircleColorBasedOnAlert = (circle) => {
		let pValue = circle.pValue;
		let ir = circle.ir;
		let alertColors = {
			yellow: "#F7B924",
			orange: "#FF6939",
			red: "#D92550",
			none: "#00000000",
			1: "#F7B924",
			2: "#FF6939",
			3: "#D92550",
			0: "#00000000"
		};
		if (circle.alerts && circle.alerts.length) return alertColors[circle.alerts[0].alert_type];
		else return alertColors[0];

		// if (pValue < 0.05) {
		// 	return alertColors.red;
		// } else {
		// 	if (ir >= 0.15 && ir < 1.5) {
		// 		return alertColors.yellow;
		// 	} else if (ir >= 1.5 && ir < 5) {
		// 		return alertColors.orange;
		// 	} else if (ir >= 5) {
		// 		return alertColors.red;
		// 	} else if (pValue < 0.1) {
		// 		return alertColors.yellow;
		// 	} else {
		// 		return alertColors.none;
		// 	}
		// }
	};

	createCirclesFromDataForMultipleDates = (dataObject) => {
		try {
			let circles = [];
			let markers = [];
			let dates = Object.keys(dataObject);
			dates.forEach((date) => {
				let circleKeys = Object.keys(dataObject[date]);
				// let color = dataObject[date].color;
				circleKeys.forEach((circle) => {
					if (circle !== "color") {
						let data = dataObject[date][circle];
						let color = this.getCircleColorBasedOnAlert(data); //.color;
						if (data.points && data.points.length > 0) markers = [...markers, ...this.createMarkersFromData(data.points, data.id)];
						circles.push(
							<Circle
								key={`circle_${data.id}`}
								radius={this.props.dots ? data.radius / 6 : data.radius}
								center={{
									lat: parseFloat(data.lat),
									lng: parseFloat(data.lng)
								}}
								strokeColor="black"
								// strokeColor={dataObject[date].color}
								strokeOpacity={1}
								strokeWeight={2}
								fillColor={color}
								fillOpacity={0.7}
								onClick={this.props.handleMapCircleClick ? () => this.props.handleMapCircleClick(data.date, data.id, data.pValue) : () => this.props.toggleModal(data)}
							/>
						);
					}
				});
			});
			return { circles, markers };
		} catch (e) {
			throw e;
		}
	};

	createMarkersFromData = (dataList, circleId) => {
		try {
			let markers = dataList.map((data, ind) => {
				// return <Marker key={`marker_${circleId}_${data.id}`} position={data} icon={BlueIcon} />;
				return <Marker position={data} icon={BlueIcon} />;
			});
			return markers;
		} catch (e) {}
	};

	render() {
		let {
			showDateHistoryButton,
			keepDateHistory,
			toggleKeepDateHistory,
			showPatientMarkerButton,
			showPlayButton,
			handlePlayButtonClick,
			isPlaying,
			loadingPois,
			showOverlapping,
			toggleShowOverlapping
		} = this.props;
		let { slider, circles, markers, historyCircles, historyMarkers, mapToggle } = this.state;
		let rangeSlider = (
			<div className="rangeSliderInnerWrap">
				<Row>
					{showPlayButton && (
						<Col sm="1" style={{ justifyContent: "center", alignContent: "center", alignItems: "center", alignSelf: "center" }}>
							<div
								className="icon-wrapper rounded-circle"
								style={{ cursor: "pointer" }}
								onClick={showPlayButton ? (handlePlayButtonClick ? handlePlayButtonClick : null) : null}
							>
								{!isPlaying && <div className="icon-wrapper-bg" style={{ background: "#3AC47D" }} />}
								{!isPlaying && <i className="fas fa-play" style={{ color: "grey" }} />}
								{isPlaying && <div className="icon-wrapper-bg" style={{ background: "#D92550" }} />}
								{isPlaying && <i className="fas fa-stop" style={{ color: "grey" }} />}
							</div>
						</Col>
					)}
					<Col sm={showPlayButton ? "11" : "12"}>
						<h6 className="sliderTitle">Date: {"" + slider.values[slider.value]}</h6>
						<BasicSlider
							min={slider.min}
							max={slider.max}
							defaultValue={slider.defaultValue}
							count={slider.count}
							onSlide={this.handleOnSlide}
							selectedTab={this.props.selectedTab}
							step={slider.step}
							values={slider.values}
						/>
					</Col>
				</Row>
			</div>
		);
		return (
			<GoogleMap
				rangeSlider={slider.active ? rangeSlider : null}
				showDateHistoryButton={showDateHistoryButton}
				showPatientMarkerButton={showPatientMarkerButton}
				keepDateHistory={keepDateHistory}
				toggleKeepDateHistory={toggleKeepDateHistory}
				getRef={this.props.getRef ? this.props.getRef : null}
				circles={circles}
				patientMarkers={markers}
				historyCircles={historyCircles}
				historyMarkers={historyMarkers}
				// mapToggle={mapToggle}
				mapToggle={false}
				isPlaying={isPlaying}
				loadingPois={loadingPois}
				showOverlapping={showOverlapping}
				toggleShowOverlapping={toggleShowOverlapping}
			/>
		);
	}
}

export default Map;
