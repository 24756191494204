import axios from "axios";
import jwtDecode from "jwt-decode";

const TOKEN_VAR = "covid-dashboard-jwt";
const UPDATE_FLAG = "tokenUpdated";

class Auth {
	static login(email, password) {
		const url = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v1/auth`;
		return axios.post(url, { email, password });
	}

	static getUserProfiles() {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/v1/auth/portal-users`, {
				headers: {
					Authorization: `${Auth.getAuthorizationToken()}`
				}
			})
			.then((response) => {
				this.setPortalUsers(response.data.portalUsers);
			})
			.catch((error) => Promise.reject(error));
	}

	static getAuthorizationToken() {
		const token = localStorage.getItem(TOKEN_VAR);
		if (token) {
			return token;
		} else {
			localStorage.clear();
		}
	}

	static setAuthorizationToken(jwt) {
		localStorage.setItem(TOKEN_VAR, `Bearer ${jwt}`);
		localStorage.setItem(UPDATE_FLAG, true);
	}

	static clearAuthToken() {
		localStorage.removeItem(TOKEN_VAR);
		localStorage.clear();
	}

	static setPortalUsers = (portalUsersArr) => {
		localStorage.setItem("portalUsersArr", JSON.stringify(portalUsersArr));
		if (portalUsersArr.length && !localStorage.getItem("currentUser")) {
			localStorage.setItem("currentUser", portalUsersArr[0]._id);
		}
	};

	static getPortalUsers = () => {
		let users = localStorage.getItem("portalUsersArr");
		users = JSON.parse(users);
		return users;
	};

	static getUserDetail() {
		const token = localStorage.getItem(TOKEN_VAR);
		if (!token) return null;
		return jwtDecode(token);
	}
}
export default Auth;
