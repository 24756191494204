import React, { Component, Fragment } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import ScrollUpButton from "react-scroll-up-button";
import PatientsTable from "./patientsTable";
import Map from "./map";
export default class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
    };
  }

  handleSelectUser = (userId) => {
    this.setState({ selectedUser: userId });
  };

  render() {
    const { selectedUser } = this.state;
    return (
      <Fragment>
        <Container fluid>
          <Row className="mt-3 height76">
            <Col sm={12} lg={4}>
              <PatientsTable
                onSelectUser={this.handleSelectUser}
                selectedUser={selectedUser}
              />
            </Col>
            <Col sm={12} lg={8}>
              <div className="tracingMap">
                <Map selectedUser={selectedUser} />
              </div>
            </Col>
          </Row>
          {/* <Row className="height76" style={{ marginTop: 20 }}>
            <Col sm="12" lg="4" className="mb-3 mb-lg-0">
              <PatientsTable
                onSelectUser={this.handleSelectUser}
                selectedUser={selectedUser}
              />
            </Col>

            <Col sm="12" lg="8" className="mb-3 mb-lg-0">
              <Card className="height76">
                <Map selectedUser={selectedUser} />
              </Card>
            </Col>
          </Row> */}
        </Container>
        <ScrollUpButton />
      </Fragment>
    );
  }
}
