import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import { Table } from "reactstrap";

class CustomTable extends Component {
	handleSort = (key) => {
		const { sortOrder, sortBy } = this.props;
		let order = "asc";
		if (key === sortBy) {
			order = sortOrder === "asc" ? "desc" : "asc";
		}
		if (this.props.onSort) this.props.onSort(key, order);
	};

	render() {
		const {
			headers,
			data,
			keys,
			sortOrder,
			sortBy,
			onRowClick,
			rowClickKey
		} = this.props;
		const caret = (
			<FontAwesomeIcon icon={sortOrder === "asc" ? faCaretDown : faCaretUp} />
		);
		return (
			<div>
				<Table
					responsive
					hover
					striped
					borderless
					className="align-middle mb-0"
				>
					<thead>
						<tr>
							{headers.map((label, index) => (
								<th className="text-center" key={`header_${index}`}>
									<span
										onClick={() => this.handleSort(keys[index])}
										style={{ cursor: "pointer" }}
									>
										{`${label} `}
										{sortBy === keys[index] && caret}
									</span>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((row, rowIndex) => {
							const columns = keys.map((key, colIndex) => (
								<td className="text-center" key={`col_${colIndex}`}>
									{row[key]}
								</td>
							));
							return (
								<tr
									onClick={() => onRowClick(row[rowClickKey])}
									key={`row_${rowIndex}`}
									style={{
										cursor: rowClickKey ? "pointer" : "default"
									}}
								>
									{columns}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		);
	}
}

CustomTable.defaultProps = {
	headers: ["#", "COLUMN 1", "COLUMN 2"],
	keys: ["id", "col_1", "col_2"],
	data: [
		{
			id: 1,
			col_1: "John",
			col_2: "Doe"
		},
		{
			id: 2,
			col_1: "Jane",
			col_2: "Doe"
		}
	],
	sortOrder: "asc",
	onRowClick: () => {}, // returns value from data with the specified key in `rowClickKey`
	rowClickKey: null
};

export default CustomTable;
