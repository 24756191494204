import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import ScrollUpButton from "react-scroll-up-button";
import { toast } from 'react-toastify';
import { CircleService } from '../../../../services';
import { filterOverlappingRedAlerts, getPlaces, getClusterUC } from '../../utils/clustersData';
import { ScreenLoader } from '../../common';
import ListView from '../../../../components/ListView/index';
import StatsModal from '../Analysis/StatsModal';
import { Map } from './'

class Lockdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clusters: {},
            weekDates: [],
            loading: true,
            selectedWeek: 0,
            selectedClusterId: null,
            selectedClusterDate: null,
            fetchingPlaces: false,
            modalVisible: false
        };
        this.weeksToDisplay = 2;
        this.temporalWindow = 7; // days
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        this.fetchClusters();
    }

    fetchClusters = async _ => {
        try {
            let { clusterData: clusters, dateList } = await CircleService.getCircles({ week: this.weeksToDisplay });
            let [ filteredClusters, weekDates ] = this.filterClusters(clusters, dateList);
            this.setState({ loading: false, clusters: filteredClusters, weekDates, selectedWeek: weekDates.length - 1 }, this.tagUnionCouncils);
        }
        catch (error) {
            toast.error("There was an error while fetching data");
        }
        
    }

    filterClusters = (clusters, dateList) => {
        let weekDates = [];
        const filteredClusters = {};
        for(let i = 0; i < dateList.length; i = i + this.temporalWindow) {
            let dates = dateList.slice(i, Math.min(i + this.temporalWindow, dateList.length));
            let weekData = {};
            dates.forEach(date => weekData[date] = clusters[date] );
            weekData = filterOverlappingRedAlerts(weekData);
            dates.forEach(date => filteredClusters[date] = weekData[date] );
            weekDates.push(dates);
        }
        return [filteredClusters, weekDates];
    }

    tagUnionCouncils = _ => {
		const { clusters } = this.state;
		getClusterUC(clusters, ucNames => {
			Object.keys(clusters).forEach(date => {
				const circles = { ...clusters[date] };
				Object.keys(circles).forEach(circleId => {
					let circle = { ...circles[circleId] };
					circle.ucName = ucNames[date][circleId];
					circles[circleId] = circle;
				});
				clusters[date] = circles;
			});
			this.setState({ clusters });
		});
	}

    handleSelectWeek = value => this.setState({ selectedWeek: value });

    handleClickCircle = (date, clusterId) => {
        this.setState({ selectedClusterId: clusterId, selectedClusterDate: date, modalVisible: true }, _ => {
            const { clusters } = this.state;
            let cluster = { ...clusters[date][clusterId] };
            const { lat, lng, radius, placesFetched } = cluster;
            if (placesFetched) return;
            this.setState({ fetchingPlaces: true })
            getPlaces(lat, lng, radius, this.mapRef.current, results => {
                cluster = { ...cluster, ...results, placesFetched: true };
                clusters[date][clusterId] = cluster;
                this.setState({ clusters, fetchingPlaces: false });
            });
        });
    }

    getMapRef = ref => this.mapRef = ref;

    toggleModal = _ => this.setState({ modalVisible: false });

    render() {
        const { loading, clusters, weekDates, selectedWeek, modalVisible, selectedClusterId, selectedClusterDate, fetchingPlaces } = this.state;
        
        if (loading) return <ScreenLoader loading={true} />;

        const filteredClusters = {};
        weekDates[selectedWeek].forEach(date => Object.keys(clusters[date]).forEach(circleId => filteredClusters[circleId] = clusters[date][circleId]));

        return (
            <Fragment>
                <Container fluid>
                    <Row className="height76" style={{ marginTop: 20 }}>
                        <Col sm="12" lg="8" className="mb-3 mb-lg-0">
                            <Card className="height76">
                                <Map
                                    clusters={filteredClusters}
                                    weekDates={weekDates}
                                    selectedWeek={selectedWeek}
                                    onSelectWeek={this.handleSelectWeek}
                                    onClickCircle={this.handleClickCircle}
                                    getMapRef={this.getMapRef}
                                />
                            </Card>
                        </Col>
                        <Col sm="12" lg="4" className="mb-3 mb-lg-0">
                            <Card className="h100" style={{ alignContent: "center" }}>
                                <ListView data={filteredClusters} />
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <StatsModal
                    visible={modalVisible}
                    toggleModal={this.toggleModal}
                    data={selectedClusterId ? filteredClusters[selectedClusterId] : {}}
                    fetchingPlaces={fetchingPlaces}
                    selectedDate={selectedClusterDate}
                    handleSendAlert={this.handleSendAlert}
                />
                <ScrollUpButton />
            </Fragment>
        );
    }
}

export default Lockdown;