import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

export default class Pie extends Component {
	constructor(props) {
		super(props);

		this.state = {
			series: [44, 55, 11],
			options: {
				chart: {
					width: 380,
					type: "pie"
				},
				labels: ["Suspected ", "+ve", "-ve"],
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200
							},
							legend: {
								position: "bottom"
							}
						}
					}
				],
				legend: {
					position: "bottom"
				}
			}
		};
	}

	render() {
		return (
			<Card className="main-card mt-3">
				<CardHeader className="py-3">{this.props.title}</CardHeader>
				<CardBody>
					{/* <CardBody style={{ height: "41vh" }}> */}
					<Chart
						options={this.state.options}
						series={this.state.series}
						type="donut"
					/>
				</CardBody>
			</Card>
		);
	}
}
