import React, { Component } from "react";
import "./App.css";
import "./architectuiassets/base.scss";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./redux/config/configureStore";
import history from "./history";
import { CovidDashboard } from "./views/covid-tracker";
import AlertFeed from "./views/covid-tracker/dashboard/AlertFeed";
import Login from "./views/covid-tracker/Login";
import Loading from "./views/covid-tracker/Loading";

const store = configureStore();
class App extends Component {
	render() {
		return (
			<main>
				<ToastContainer />
				<Provider store={store}>
					<BrowserRouter history={history}>
						<Switch>
							<Route exact path="/" component={Loading} />
							<Route exact path="/covid" component={CovidDashboard} />
							<Route exact path="/alert" component={AlertFeed} />
							<Route exact path="/login" component={Login} />
						</Switch>
					</BrowserRouter>
				</Provider>
			</main>
		);
	}
}

export default App;
