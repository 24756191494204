import React from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

import CountUp from "react-countup";

import { NewsFeed } from "../Table/NewsFeed";
import Pie from "../Graphs/Pie";
import { Link } from "react-router-dom";
// import Line from "../Graphs/Line";
import moment from "moment";
import { XAxis, ResponsiveContainer, BarChart, Bar, Line, Area, AreaChart, ComposedChart, YAxis } from "recharts";

const calculateDatesInRangeFromCurrent = (min, max, date) => {
	try {
		let dates = [];
		let currentDate = new Date(date);
		for (let i = min; i <= max; i++) {
			let previous = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000);
			dates.push(moment(previous).format("DD MMM"));
		}
		return dates;
	} catch (e) {}
};

const createTseirForecastData = (cases, forecastDates, infected) => {
	let dates = forecastDates;
	let tseirForecastData = [];
	for (let i = 0; i < dates.length; i++) {
		tseirForecastData.push({
			name: "" + dates[i],
			predicted: infected[i]
		});
	}
	return tseirForecastData;
};

const MonitorModal = ({ title, visible, toggleModal, data }) => {
	let daysUpperLimit = 6;
	let forecastDates,
		tseirForecastData = [];
	if (data) {
		daysUpperLimit = data.infected ? data.infected.length - 1 : 6;
		forecastDates = calculateDatesInRangeFromCurrent(0, daysUpperLimit, data.date ? data.date : new Date());
		tseirForecastData = createTseirForecastData(data.cases, forecastDates, data.infected);
	}
	return (
		<Modal isOpen={visible} onClose={toggleModal} showMask={false} size="lg">
			<ModalHeader toggle={toggleModal}>{title} Radius: 50m</ModalHeader>
			<ModalBody className="modalBodyScrollInside" style={{ backgroundColor: "#ececec" }}>
				<Row>
					<Col>
						<Card className="mb-1">
							<CardHeader className="rm-border pb-0 responsive-center">
								<div>
									<h5 className="menu-header-title text-capitalize">Alert Performance</h5>
								</div>
							</CardHeader>
							<Row>
								<Col sm="12" md="6">
									<div className="widget-chart">
										<div className="widget-chart-content">
											<div className="widget-subheading">Estimated Population</div>
											<div className="widget-numbers text-success">
												<CountUp start={0} end={563} separator="" decimals={0} decimal="." useEasing={false} suffix="" duration="2" />
											</div>
										</div>
									</div>
								</Col>
								<Col sm="12" md="6">
									<Link className="widget-chart" to="/alert" target="blank">
										<div className="widget-chart-content">
											<div className="widget-subheading">Test Conducted</div>
											<div className="widget-numbers text-danger">
												<CountUp start={0} end={234} separator="" decimals={0} decimal="," useEasing={false} suffix="" duration="2" />
											</div>
										</div>
									</Link>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col lg="6" md="6" xl="6" sm="12" className="mt-3">
						<Card>
							<CardTitle className="m-3">TSEIR Forecast</CardTitle>
							{/* <ResponsiveContainer> */}
							<CardBody>
								<ResponsiveContainer height={260}>
									<ComposedChart data={tseirForecastData}>
										<XAxis dataKey="name" />
										<YAxis dataKey="predicted" />

										<Line dataKey="predicted" stroke="#D92550" strokeDasharray="3 3" />
									</ComposedChart>
								</ResponsiveContainer>
							</CardBody>
						</Card>
					</Col>
					<Col lg="6" md="6" xl="6" sm="12">
						<ResponsiveContainer height={260}>
							<Pie title="Test Results" />
						</ResponsiveContainer>
					</Col>
				</Row>
				<Row>
					<Col lg="12" md="12" xl="12" sm="12">
						<NewsFeed title="Compliance Feed" tableColumns={tableColumns} data={tableData} />
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={toggleModal}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default MonitorModal;

const tableColumns = [{ name: "Name" }, { name: "Mobile number" }, { name: "CNIC" }, { name: "Age" }, { name: "Gender" }, { name: "Test Result" }];

const tableData = [
	{
		name: "Anzal Sheikh",
		number: "+93428474639",
		cnic: "3404-123456-2",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Tayyab Ikram",
		number: "+934341234523",
		cnic: "3404-123456-1",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Muhammad Hamid",
		number: "+923217847884",
		cnic: "3404-123456-3",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Anzal Sheikh",
		number: "+93428474639",
		cnic: "3404-123456-2",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Tayyab Ikram",
		number: "+934341234523",
		cnic: "3404-123456-1",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Muhammad Hamid",
		number: "+923217847884",
		cnic: "3404-123456-3",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Anzal Sheikh",
		number: "+93428474639",
		cnic: "3404-123456-2",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Tayyab Ikram",
		number: "+934341234523",
		cnic: "3404-123456-1",
		gender: "Male",
		age: "23",
		result: "Positive"
	},
	{
		name: "Muhammad Hamid",
		number: "+923217847884",
		cnic: "3404-123456-3",
		gender: "Male",
		age: "23",
		result: "Positive"
	}
];
