import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Slider } from "react-burgers";
import AppMobileMenu from "../AppMobileMenu";
import SALogo from "../../assets/images/logo.svg";
import cx from "classnames";
import {
	setEnableClosedSidebar,
	setEnableMobileMenu,
	setEnableMobileMenuSmall
} from "../../redux/reducers/ThemeOptions";
class HeaderLogo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			mobile: false,
			activeSecondaryMenuMobile: false
		};
	}

	toggleEnableClosedSidebar = () => {
		let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
		setEnableClosedSidebar(!enableClosedSidebar);
	};

	state = {
		openLeft: false,
		openRight: false,
		relativeWidth: false,
		width: 280,
		noTouchOpen: false,
		noTouchClose: false
	};

	render() {
		return (
			<Fragment>
				<div className="app-header__logo">
					<div className="logo-src ml-n2">
						<a href="/" className="">
							<img src={SALogo} />
						</a>
					</div>
					<div className="switchWrapper tablet">
						<div
							className="switch has-switch mb-2 mr-2"
							data-on-label="Analysis"
							data-off-label="Monitor"
							onClick={this.props.toggleSwitch}
						>
							<div
								className={cx("switch-animate", {
									"switch-on": this.props.isToggleOn,
									"switch-off": !this.props.isToggleOn
								})}
							>
								<input type="checkbox" />
								<span className="switch-left bg-primary">Analysis</span>
								<label>&nbsp;</label>
								<span className="switch-right bg-primary">Monitor</span>
							</div>
						</div>
					</div>
					<div className="header__pane ml-auto">
						<div onClick={this.toggleEnableClosedSidebar}>
							<Slider
								width={26}
								lineHeight={2}
								lineSpacing={5}
								color="#6c757d"
								active={this.state.active}
								onClick={() => this.setState({ active: !this.state.active })}
							/>
						</div>
					</div>
				</div>
				<AppMobileMenu />
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
});

const mapDispatchToProps = dispatch => ({
	setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
	setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
	setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
