import http from './http';

class PolygonService {

    static BASE_URL = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v1/polygon`

    static getPolygons = user_id => {
        const url = `${this.BASE_URL}/get-all`;
        return http.post(url, { user_id })
    };
}

export default PolygonService;