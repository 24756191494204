import React, { Component } from "react";
import LoaderSVG from "../../assets/images/loader.svg";
import { Redirect } from "react-router-dom";
import { Auth } from "../../services";
import { toast } from "react-toastify";

export default class Loading extends Component {
	constructor(props) {
		super(props);
		this.state = { auth: false };
	}
	async componentWillMount() {
		let auth = Auth.getAuthorizationToken();
		setTimeout(async () => {
			if (auth) {
				try {
					let userProfiles = await Auth.getUserProfiles();
				} catch (error) {
					toast.error("Cannot find user profiles");
				}
				this.props.history.push("/covid");
			} else {
				this.props.history.push("/login");
			}
		}, 1000);
	}
	render() {
		return (
			<div id="cloud-container" style={{ backgroundColor: "#000", justifyContent: "center", alignItems: "center", height: "100%" }}>
				<img src={LoaderSVG} className="loadersvg" img="source" style={{ alignSelf: "center", marginLeft: "40%", marginTop: "20%" }} alt="loader" />
			</div>
		);
	}
}
