import axios from "axios";
import Auth from "./auth";
const URL = process.env.REACT_APP_BACKEND_API_HOST + "/api/v1/pois";

class PoiService {
    static getCirclePois = (circleIds, category) => {
        return axios.post(`${URL}/pois-by-circle-list`, {
            circle_id: circleIds,
            category
        },
        {
            headers: {
                Authorization: `${Auth.getAuthorizationToken()}`
            }
        });
    }
}
export default PoiService;