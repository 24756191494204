import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../redux/reducers/ThemeOptions";
import { MainNav } from "./NavItems";

let tabtext = null;

class Nav extends Component {
	state = {};

	toggleMobileSidebar = () => {
		let { enableMobileMenu, setEnableMobileMenu } = this.props;
		setEnableMobileMenu(!enableMobileMenu);
	};

	handleSelect = (text) => {
		if (tabtext === text) {
			tabtext = null;
		} else {
			tabtext = text;
		}
	};

	render() {
		return (
			<Fragment>
				<h5 className="app-sidebar_heading"></h5>
				<MetisMenu
					content={this.props.menuItems}
					onSelected={(e) => {
						this.toggleMobileSidebar();
						this.props.onChangeLeftMenu(e.target.text);
						this.handleSelect(e.target.text);
					}}
					// activeLinkLabel
					// activeLinkFromLocation		// TODO: COMMENTED THIS TO REMOVE ERRORS, PLEASE REVIEW WHEN FIXING LEFT MENU
					className="vertical-nav-menu"
					iconNamePrefix=""
					classNameStateIcon="pe-7s-angle-down"
					// classNameItemActive={"selectedItem"}
				/>
				{/* <div style={{ width: "90%", backgroundColor: "#000", height: 1 }}></div>
				<MetisMenu
					content={this.props.menuItems.left1}
					onSelected={e => {
						this.toggleMobileSidebar();
						this.props.onChangeLeftMenu(e.target.text);
						this.handleSelect(e.target.text);
					}}
					activeLinkLabel
					activeLinkFromLocation
					className="vertical-nav-menu"
					iconNamePrefix=""
					classNameStateIcon="pe-7s-angle-down"
					// classNameItemActive={"selectedItem"}
				/> */}
			</Fragment>
		);
	}

	isPathActive(path) {
		return this.props.location.pathname.startsWith(path);
	}
}
const mapStateToProps = (state) => ({
	enableMobileMenu: state.ThemeOptions.enableMobileMenu
});

const mapDispatchToProps = (dispatch) => ({
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
